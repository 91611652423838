import { TPhotography } from '@/components/pages/Events/Show/Photography/types';
import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { TextArea } from '@/components/shared/Form/Inputs';
import { Alert } from '@/components/shared/Alert';
import { ownerUrl } from '@/ts/url';
import { useParams } from 'react-router-dom';

type GroupPhotoProps = {
  photography: TPhotography;
  validator: TValidator;
};

type RouteParams = {
  eventId: string;
};

export const GroupPhoto: React.FC<GroupPhotoProps> = ({
  photography,
  validator,
}) => {
  const { eventId } = useParams<RouteParams>();
  return (
    <PhotographyAccordion title="集合写真撮影">
      <div>
        {photography.hasGroupPhoto === null
          ? '未定'
          : photography.hasGroupPhoto
          ? 'あり'
          : 'なし'}
      </div>
      <Alert type="error" title="集合写真有無の変更について">
        <p>申請サマリの項目のため申請サマリ編集から対応をお願いいたします。</p>
        <a
          className="c-btn c-btn_middle_wide c-btn_black"
          href={ownerUrl(`events/requests/${eventId}`)}
          target="_blank"
          rel="noreferrer"
        >
          申請サマリ編集
        </a>
      </Alert>
      <div className="t-textColor_sub">詳細</div>
      <TextArea
        name="groupPhotoSenNote"
        validator={validator}
        placeholder="集合写真撮影の詳細を入力してください"
      />
    </PhotographyAccordion>
  );
};

import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '../../Shared/Accordion/App';
import { RadioBoxes, TextArea } from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED } from '../../const';
import { TFormInputs } from '../../types';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

export const Tripod: React.FC<{ validator: TValidator }> = ({ validator }) => {
  const { watch, setValue } = useFormContext<TFormInputs>();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'isTripodPermitted' && type === 'change') {
        if (value.isTripodPermitted !== 1 && value.isTripodPermitted !== 0) {
          setValue('tripodSenNote', '');
        }
        if (value.isTripodPermitted !== 1) {
          setValue('requireOwnTripod', ID_UNCONFIRMED);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);
  return (
    <PhotographyAccordion title="三脚使用">
      <RadioBoxes
        name="isTripodPermitted"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: '可能' },
          { key: 0, value: '不可' },
        ]}
      />
      {watch('isTripodPermitted') === 1 && (
        <>
          <div className="t-textColor_sub">持参</div>
          <RadioBoxes
            name="requireOwnTripod"
            validator={validator}
            choices={[
              { key: ID_UNCONFIRMED, value: '未確認' },
              { key: 1, value: '必要' },
              { key: 0, value: '不要' },
            ]}
          />
        </>
      )}
      {(watch('isTripodPermitted') === 1 ||
        watch('isTripodPermitted') === 0) && (
        <>
          <div className="t-textColor_sub">詳細</div>
          <TextArea
            name="tripodSenNote"
            validator={validator}
            placeholder="三脚使用に関する詳細を入力してください"
          />
        </>
      )}
    </PhotographyAccordion>
  );
};

import {
  TConstants,
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { UseFormReturn } from 'react-hook-form';
import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { Alert } from '@/components/shared/Alert';
import { useParams } from 'react-router';
import { ownerUrl } from '@/ts/url';
import React, { useEffect } from 'react';
import {
  Checkboxes,
  RadioBoxes,
  TextArea,
} from '@/components/shared/Form/Inputs';
import {
  EVENT_TYPE_ID_RECITAL,
  EVENT_TYPE_ID_RECITAL_REHEARSAL,
  ID_UNCONFIRMED,
} from '@/components/pages/Events/Show/Photography/const';
import {
  shouldRenderBackPaper,
  shouldRenderOtherSpecialEquipment,
  shouldRenderStrobe,
  shouldRenderTieredPlatform,
} from '@/components/pages/Events/Show/Photography/Shared/shouldRenderPhotographyContent';
import { getUnchangeableEquipmentTypes } from '@/components/pages/Events/Show/Photography/Shared/getUnchangeableEquipmentTypes';

const EquipmentType: React.FC<{
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
}> = ({ photography, constants, validator }) => {
  const unchangeableKeys = getUnchangeableEquipmentTypes(photography);
  const equipmentTypes = constants.photographyEquipmentTypes.map(
    (equipmentType) => ({
      ...equipmentType,
      isDisabled: unchangeableKeys.includes(Number(equipmentType.key)),
    })
  );

  return (
    <>
      <Checkboxes
        name="equipmentTypeIds"
        validator={validator}
        choices={equipmentTypes}
      />
    </>
  );
};

export const Equipment: React.FC<{
  photography: TPhotography;
  constants: TConstants;
  methods: UseFormReturn<TFormInputs>;
  validator: TValidator;
}> = ({ photography, constants, methods, validator }) => {
  const { eventId } = useParams<{ eventId: string }>();

  useEffect(() => {
    if (!shouldRenderTieredPlatform(photography)) {
      methods.setValue('tieredPlatformSenNote', '');
    }
    if (!shouldRenderStrobe(photography, methods.watch())) {
      methods.setValue('isStrobePermitted', ID_UNCONFIRMED);
      methods.setValue('strobeSenNote', '');
    }
    const subscription = methods.watch((value, { name, type }) => {
      if (name === 'equipmentTypeIds' && type === 'change') {
        if (!shouldRenderStrobe(photography, methods.watch())) {
          methods.setValue('isStrobePermitted', ID_UNCONFIRMED);
          methods.setValue('strobeSenNote', '');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [photography, methods]);

  return (
    <PhotographyAccordion title="機材">
      <Alert
        type="error"
        title="ひな壇・モノブロック・バック紙・スタンド・その他特殊機材について"
      >
        <p>必要になった場合は申請サマリ編集から対応をお願いいたします。</p>
        <a
          className="c-btn c-btn_middle_wide c-btn_black"
          href={ownerUrl(`events/requests/${eventId}`)}
          target="_blank"
          rel="noreferrer"
        >
          申請サマリ編集
        </a>
      </Alert>
      <EquipmentType
        photography={photography}
        constants={constants}
        validator={validator}
      />
      {shouldRenderTieredPlatform(photography) && (
        <>
          <hr className="u-line_plane" />
          <div className="t-textColor_sub">ひな壇の種類</div>
          <div className="t-bgBox_gray">
            {photography.tieredPlatformNameRemark !== null
              ? photography.tieredPlatformNameRemark
              : photography.tieredPlatformName}
          </div>
          <div className="t-textColor_sub">必要なセット数</div>
          <div className="t-bgBox_gray">
            {photography.tieredPlatformSetRemark !== null
              ? photography.tieredPlatformSetRemark
              : photography.tieredPlatformSetName}
          </div>
          <div className="t-textColor_sub">ひな壇の共有事項</div>
          <TextArea
            name="tieredPlatformSenNote"
            validator={validator}
            placeholder="ひな壇の共有事項を入力してください"
          />
        </>
      )}
      {shouldRenderBackPaper(photography) && (
        <>
          <hr className="u-line_plane" />
          <div className="t-textColor_sub">バック紙保管場所</div>
          <div className="t-bgBox_gray">
            {photography.backPaperStoragePlace}
          </div>
        </>
      )}
      {shouldRenderOtherSpecialEquipment(photography) && (
        <>
          <hr className="u-line_plane" />
          <div className="t-textColor_sub">その他特殊機材詳細</div>
          <div className="t-bgBox_gray">
            {photography.otherSpecialEquipmentRemark}
          </div>
        </>
      )}
      {shouldRenderStrobe(photography, methods.watch()) && (
        <>
          <hr className="u-line_plane" />
          <div className="t-textColor_sub">ストロボ使用（フラッシュ）</div>
          {photography.eventTypeId === EVENT_TYPE_ID_RECITAL ||
          photography.eventTypeId === EVENT_TYPE_ID_RECITAL_REHEARSAL ? (
            <>
              <label className="c-radioLabel">
                <input
                  type="radio"
                  className="c-radioBtn"
                  checked={photography.isStrobePermitted === null}
                  disabled={true}
                />
                <span className="c-label_radioBtn is-disabled">未確認</span>
              </label>
              <label className="c-radioLabel">
                <input
                  type="radio"
                  className="c-radioBtn"
                  checked={photography.isStrobePermitted === true}
                  disabled={true}
                />
                <span className="c-label_radioBtn is-disabled">可能</span>
              </label>
              <label className="c-radioLabel">
                <input
                  type="radio"
                  className="c-radioBtn"
                  checked={photography.isStrobePermitted === false}
                  disabled={true}
                />
                <span className="c-label_radioBtn is-disabled">不可</span>
              </label>
            </>
          ) : (
            <RadioBoxes
              name="isStrobePermitted"
              validator={validator}
              choices={[
                { key: ID_UNCONFIRMED, value: '未確認' },
                { key: 1, value: '可能' },
                { key: 0, value: '不可' },
              ]}
            />
          )}
          <div className="t-textColor_sub">ストロボの共有事項</div>
          <TextArea
            name="strobeSenNote"
            validator={validator}
            placeholder="ストロボの共有事項を入力してください"
          />
        </>
      )}
      <hr className="u-line_plane" />
      <div className="t-textColor_sub">詳細</div>
      <TextArea
        name="equipmentSenNote"
        validator={validator}
        placeholder="詳細を入力してください"
      />
    </PhotographyAccordion>
  );
};

import React from 'react';
import { TValidator } from '@/components/shared/Form/types';
import { TConstants } from '@/components/pages/Events/Show/Photography/types';
import { EnvironmentType } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Shared/EnvironmentType';
import { NapPhoto } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Shared/NapPhoto';
import { RadioBoxes } from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED } from '@/components/pages/Events/Show/Photography/const';

export const Ceremony: React.FC<{
  validator: TValidator;
  constants: TConstants;
}> = ({ validator, constants }) => {
  return (
    <>
      <EnvironmentType
        validator={validator}
        photographyEnvironmentTypes={constants.photographyEnvironmentTypes}
      />

      <hr className="u-line_plane" />
      <NapPhoto validator={validator} />

      <hr className="u-line_plane" />

      <div className="t-textColor_sub">正装</div>
      <RadioBoxes
        name="hasDressCode"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: '必要' },
          { key: 0, value: '不要' },
        ]}
      />
    </>
  );
};

import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '../../Shared/Accordion/App';
import {
  Checkboxes,
  RadioBoxes,
  TextArea,
} from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED } from '../../const';
import { useFormContext } from 'react-hook-form';
import { TConstants, TFormInputs } from '../../types';
import { useEffect } from 'react';
import { LIST_ITEMS } from '../../listItem';

export const SignboardPhoto: React.FC<{
  validator: TValidator;
  constants: TConstants;
}> = ({ validator, constants }) => {
  const { watch, setValue } = useFormContext<TFormInputs>();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasSignboardPhoto' && type === 'change') {
        if (value.hasSignboardPhoto !== 1) {
          setValue('signboardPhotoTimingTypeIds', []);
          setValue(
            'signboardPhotoTargetMessageTypeId',
            constants.signboardPhotoTimingTypes[0].key
          );
          setValue('signboardPhotoRemark', '');
        } else {
          setValue(
            'signboardPhotoTargetMessageTypeId',
            constants.signboardPhotoTimingTypes[0].key
          );
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue, constants.signboardPhotoTimingTypes]);
  return (
    <PhotographyAccordion
      title={LIST_ITEMS.photography.signboardPhoto.name}
      id={LIST_ITEMS.photography.signboardPhoto.id}
    >
      <RadioBoxes
        name="hasSignboardPhoto"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: 'あり' },
          { key: 0, value: 'なし' },
        ]}
      />
      <hr className="u-line_plane" />
      {watch('hasSignboardPhoto') === 1 && (
        <>
          <div className="t-textColor_sub">撮影タイミング</div>
          <Checkboxes
            name="signboardPhotoTimingTypeIds"
            validator={validator}
            choices={constants.signboardPhotoTimingTypes}
          />
          <div className="t-textColor_sub">注釈選択</div>
          <RadioBoxes
            name="signboardPhotoTargetMessageTypeId"
            validator={validator}
            choices={constants.signboardPhotoTargetMessageTypes}
          />
          <div className="t-textColor_sub">詳細</div>
          <TextArea
            name="signboardPhotoRemark"
            validator={validator}
            placeholder="看板前撮影の詳細を入力してください"
          />
        </>
      )}
    </PhotographyAccordion>
  );
};

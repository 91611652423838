import React from 'react';
import { TOverviewResponse } from '@/components/pages/Events/Show/Overview/types';
import { Link } from 'react-router-dom';
import { ListItem } from '@/components/pages/Events/Show/Overview/ListItem';

export const Arrangement: React.FC<{ response: TOverviewResponse }> = ({
  response,
}) => {
  return (
    <div className="u-pdb_s u-mgb_m">
      <h2>手配関連</h2>
      <ListItem>
        <ul>
          <li>指定カメラマン</li>
          <li>
            {response.arrangement.desiredPhotographers.map(
              (desiredPhotographer) => (
                <Link to={'/photographers/' + desiredPhotographer.id}>
                  {desiredPhotographer.name}
                </Link>
              )
            )}
          </li>
        </ul>
        <ul>
          <li>カメラマン派遣</li>
          <li>{response.arrangement.photographerRequiredName}</li>
        </ul>
        <ul>
          <li>カメラマン人数</li>
          <li>
            <p>
              &#9678; 社外カメラマン : {response.arrangement.cameramanOutside}人
            </p>
            <p>
              &#9651; 社内カメラマン : {response.arrangement.cameramanInside}人
            </p>
            {(response.arrangement.numberOfTrainees || 0) > 0 && (
              <p>研修カメラマン : {response.arrangement.numberOfTrainees}人</p>
            )}
          </li>
        </ul>
        <ul>
          <li>カメラマン制限</li>
          <li>{response.arrangement.photographerConstraint}</li>
        </ul>
        <ul>
          <li>内定者カメの研修</li>
          <li>{response.arrangement.insideTraineePhotographersPermitted}</li>
        </ul>
        <ul>
          <li>相カメの研修</li>
          <li>{response.arrangement.outsideTraineePhotographersPermitted}</li>
        </ul>
        <ul>
          <li>カメラマンの性別希望</li>
          <li>{response.arrangement.requestGenderTypeName}</li>
        </ul>
        <ul>
          <li>車の必要有無</li>
          <li>{response.arrangement.requireCar}</li>
        </ul>
        <ul>
          <li>機材等</li>
          <li>
            <p>正装 : {response.arrangement.dressCodeName}</p>
            <p>ひな壇 : {response.arrangement.standName}</p>
            {response.arrangement.stand === '1' && (
              <>
                <p>ひな壇の種類 : {response.arrangement.standTypeName}</p>
                <p>ひな壇の種類メモ : {response.arrangement.standTypeMemo}</p>
                <p>ひな壇セット数 : {response.arrangement.standCount}</p>
                <p>
                  ひな壇セット数メモ : {response.arrangement.standCountMemo}
                </p>
                <p>集合写真 : {response.arrangement.groupPhotoName}</p>
              </>
            )}
          </li>
        </ul>
        <ul>
          <li>場所等</li>
          <li>
            <p>集合場所 : {response.arrangement.meetingPlaceName}</p>
            <p>集合場所メモ : {response.arrangement.meetingPlaceMemo}</p>
            <p>撮影場所 : {response.arrangement.photographingPlaceName}</p>
            <p>撮影場所メモ : {response.arrangement.photographingPlaceMemo}</p>
            <p>雨天時 : {response.arrangement.rainyCancellationTypeName}</p>
            {response.arrangement.rainyCancellationType === 1 && (
              <>
                <p>
                  {`雨天時の集合場所 : ${
                    response.arrangement.rainyMeetingPlaceName ?? ''
                  }`}
                </p>
                <p>
                  {`雨天時の集合場所メモ : ${
                    response.arrangement.rainyMeetingPlaceMemo ?? ''
                  }`}
                </p>
                <p>
                  {`雨天時の撮影場所 : ${
                    response.arrangement.rainyPhotographingPlaceName ?? ''
                  }`}
                </p>
                <p>
                  {`雨天時の撮影場所メモ : ${
                    response.arrangement.rainyPhotographingPlaceMemo ?? ''
                  }`}
                </p>
              </>
            )}
          </li>
        </ul>
        <ul>
          <li>メモ</li>
          <li>{response.arrangement.memo}</li>
        </ul>
      </ListItem>
    </div>
  );
};

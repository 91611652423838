import React from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { SearchParams } from '../../hooks';
import { useMailLogSearchPanel } from './hooks';
import Stack from '@/components/shared/Stack';
import clsx from 'clsx';
import { ContentPanel } from '@/components/shared/ContentPanel';
import { FormActions } from '@/components/shared/Form/FormActions';
import { Button } from '@/components/shared/Button';
import styles from './index.module.scss';
import { FormItem } from '@/components/shared/Form/FormItem';
import { LegendTable } from '../LegendTable';
import { NoticePanel } from '../NoticePanel';
import '../../../../../css/component.scss';

export type Props = {
  doSubmit: SubmitHandler<SearchParams>;
  email?: string;
};

export const SearchPanel = React.memo<Props>(function SearchPanel(props) {
  const { open, setOpen, formMethods } = useMailLogSearchPanel(props);
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = formMethods;

  return (
    <ContentPanel>
      <p className={styles.caption}>
        過去二ヶ月分のメール送信ログをsendgrid（メール送信のための外部サービス）から検索します。
      </p>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(props.doSubmit)}>
          <FormItem title="メールアドレス" wideTitle={true}>
            <Stack direction="column">
              <input
                type="text"
                {...register('email')}
                className={clsx('c-input_plane', errors.email && 'is-error')}
                placeholder="メールアドレス"
              />
              {errors.email && (
                <p className={styles.error}>{errors.email?.message}</p>
              )}
            </Stack>
          </FormItem>
          <FormActions>
            <Button value="検索" type="submit" variant="search" />
          </FormActions>
        </form>
      </FormProvider>
      <div className="p-btn_toggle">
        <div
          className="c-btn_toggleDetails"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <span className="u-spOff">{open ? '閉じる' : '凡例を見る'}</span>
        </div>
      </div>
      <div className={`c-accordion_content ${open && 'is-open'}`}>
        <LegendTable />
        <NoticePanel />
      </div>
    </ContentPanel>
  );
});

import { TConstants, TPhotography, TFormInputs } from '../../../types';
import dayjs from 'dayjs';
import { toConstantsText } from '../convertText/toConstantsText/toConstantsText';
import { toRadioBtnText } from '@/components/pages/Events/Show/Photography/Shared/getPhotographyMailText/convertText/toRadioBtnText/toRadioBtnText';
import {
  BON_DANCE_STYLE_TYPE_OTHER,
  BON_DANCE_STYLE_TYPE_IN_CIRCLE,
  CANCELLABLE_DAY_TYPE_ID_THAT_DAY,
  TRANSPORTATION_TYPE_BICYCLE,
  TRANSPORTATION_TYPE_CAR,
  TRANSPORTATION_TYPE_MOTORCYCLE,
  EVENT_TYPE_ID_SLEEPOVER,
  RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_NO_CHANGE,
  RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_TEXT_NO_CHANGE,
  RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_TEXT_HAS_CHANGE,
  RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_TEXT_CANCEL,
  RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_HAS_CHANGE,
  HAS_MEETING_PLACE_CHANGE_WHEN_RAINY_TEXT_TRUE,
  GIFT_STYLE_TYPE_OTHER,
  REST_PLACE_TYPE_ID_OTHER,
  PARENT_SEAT_TYPE_ID_OTHER,
  LIGHTING_TYPE_ID_OTHER,
  MEDAL_AWARD_STYLE_TYPE_OTHER,
  SPECIFIED_DIPLOMA_AWARD_PHOTOGRAPHY_POSITION_TYPE_ID_OTHER,
  SPECIFIED_DIPLOMA_AWARD_PHOTOGRAPHY_POSITION_TYPE_ID_NO_POSITION,
  DIPLOMA_AWARD_TYPE_ID_PER_PERSON,
  DIPLOMA_AWARD_TYPE_ID_REPRESENTATIVE,
  DIPLOMA_AWARD_TYPE_ID_OTHER,
  ID_UNCONFIRMED,
  OTHER_EVENT_TYPE_ID_CEREMONY,
  OTHER_EVENT_TYPE_ID_BIRTHDAY_PARTY,
  OTHER_EVENT_TYPE_ID_FARMING_EXPERIENCE,
  OTHER_EVENT_TYPE_ID_PLAYING_SHOP,
  FARMING_EXPERIENCE_DIGGING_METHOD_OTHER_TYPE,
  POOL_CLOTHES_TYPE_ID_OTHER,
  RAINY_DAY_ACTION_ID_NO_CANCEL,
  CANCEL_NOTIFICATION_TYPE_ID_OTHER,
  EVENT_TYPE_ID_ENTRANCE_CEREMONY,
  PARTICIPANT_TYPE_ID_STAFF,
  PARTICIPANT_TYPE_ID_CURRENT_PRESCHOOLERS,
  PARTICIPANT_TYPE_ID_GRADUATION_PRESCHOOLERS,
  PARTICIPANT_TYPE_ID_PARENT,
  PARTICIPANT_TYPE_ID_EXTERNAL_INSTRUCTOR,
  PARTICIPANT_TYPE_ID_EXTERNAL,
  PARTICIPANT_TYPE_ID_OTHER,
} from '../../../const';
import { stripNewLineForBlankTemplate } from '../stripNewLineForBlankTemplate';
import {
  shouldRenderAlbumOption,
  shouldRenderBackPaper,
  shouldRenderOtherSpecialEquipment,
  shouldRenderRentalStepladder,
  shouldRenderRest,
  shouldRenderSignboardPhoto,
  shouldRenderSpecifiedPosition,
  shouldRenderStrobe,
  shouldRenderTieredPlatform,
  shouldRenderTripod,
} from '@/components/pages/Events/Show/Photography/Shared/shouldRenderPhotographyContent';
import { getUnchangeableEquipmentTypes } from '@/components/pages/Events/Show/Photography/Shared/getUnchangeableEquipmentTypes';

const toNumberArray = (array: string[]) =>
  array.map((value) => parseInt(value));

function suffixedNumber(value: number | null | undefined, suffix = ''): string {
  if (value === null || value === undefined || isNaN(value)) {
    return '未確認';
  }
  return `${value}${suffix}`;
}

export const getBasic = (
  photography: TPhotography,
  inputValues: TFormInputs
): string => {
  return stripNewLineForBlankTemplate`---◇ 基本情報 ◇---

■イベント名 : ${photography.eventName}

${
  photography.eventTypeId === EVENT_TYPE_ID_SLEEPOVER
    ? `${
        photography.photographingDayCount
          ? `■撮影日数 : ${photography.photographingDayCount}日間`
          : ''
      }`
    : ''
}

■撮影日 : ${dayjs(photography.photographingDay).format('YYYY/MM/DD（ddd）')}

■撮影時間 : ${photography.photographingStartTime}〜${
    photography.photographingEndTime
  }

■集合時間 : ${
    inputValues.manualMeetingTime
      ? dayjs(
          inputValues.manualMeetingTime,
          inputValues.manualMeetingTime.length === 8 ? 'HH:mm:ss' : 'HH:mm'
        ).format('HH:mm')
      : photography.automaticallyCalculatedMeetingTime
  }
`;
};

export const getMeetingPlace = (
  photography: TPhotography,
  inputValues: TFormInputs,
  constants: TConstants
): string => {
  const isCarSelected =
    inputValues.meetingPlaceTransportationTypeIds?.includes(
      TRANSPORTATION_TYPE_CAR
    ) ||
    inputValues.meetingPlaceTransportationTypeIds?.includes(
      TRANSPORTATION_TYPE_MOTORCYCLE
    );

  const isBicycleSelected =
    inputValues.meetingPlaceTransportationTypeIds?.includes(
      TRANSPORTATION_TYPE_BICYCLE
    ) ||
    inputValues.meetingPlaceTransportationTypeIds?.includes(
      TRANSPORTATION_TYPE_MOTORCYCLE
    );
  return stripNewLineForBlankTemplate`---◇ 集合場所 ◇---

■場所詳細 : ${photography.meetingPlace ?? '園'}
${photography.meetingPlaceMemo || ''}

■行き方
${inputValues.meetingPlaceAccess ? inputValues.meetingPlaceAccess : 'なし'}

■交通機関 : ${toConstantsText(
    constants.photographyTransportationTypes,
    toNumberArray(inputValues.meetingPlaceTransportationTypeIds)
  )}
${
  isCarSelected
    ? `
■駐車場 : ${toRadioBtnText(
        'hasCarParkingLotAtMeetingPlace',
        inputValues.hasCarParkingLotAtMeetingPlace
      )}
${
  inputValues.meetingPlaceCarParkingLotRemark
    ? `□詳細\n${inputValues.meetingPlaceCarParkingLotRemark}\n`
    : ''
}`
    : ''
}
${
  isBicycleSelected
    ? `■駐輪場 : ${toRadioBtnText(
        'hasBicycleParkingLotAtMeetingPlace',
        inputValues.hasBicycleParkingLotAtMeetingPlace
      )}
${
  inputValues.meetingPlaceBicycleParkingLotRemark
    ? `□詳細\n${inputValues.meetingPlaceBicycleParkingLotRemark}`
    : ''
}`
    : ''
}
`;
};

export const getPhotographingPlace = (
  inputValues: TFormInputs,
  photography: TPhotography
): string => {
  const photographingPlaces = inputValues.photographingPlaces.filter(
    (place) => place.name && place.address
  );
  return stripNewLineForBlankTemplate`---◇ 撮影場所 ◇---

■場所詳細 : ${photography.photographingPlace ?? '園'}
${
  photographingPlaces.length === 0
    ? photography.photographingPlaceMemo ?? ''
    : ''
}
${
  photographingPlaces.length > 0
    ? '\n' +
      photographingPlaces
        .map(
          (place) => `・施設名 : ${place.name}
・住所 : ${place.address}`
        )
        .join('\n\n')
    : ''
}
`;
};

export const getPhotographySubject = (
  inputValues: TFormInputs,
  photography: TPhotography,
  constants: TConstants
): string => {
  // カンマ区切りで表示させないといけないため一旦配列に入れてからjoin(' , ')する
  const participantPhotographiesPermitted = [];
  participantPhotographiesPermitted.push(
    `${
      inputValues.participantTypeIds.includes(PARTICIPANT_TYPE_ID_STAFF)
        ? `職員写り込み : ${toRadioBtnText(
            'isStaffParticipantPhotographyPermitted',
            inputValues.isStaffParticipantPhotographyPermitted
          )}`
        : ''
    }`
  );
  participantPhotographiesPermitted.push(
    `${
      inputValues.participantTypeIds.includes(
        PARTICIPANT_TYPE_ID_CURRENT_PRESCHOOLERS
      )
        ? `在籍児童写り込み : ${toRadioBtnText(
            'isCurrentPreschoolersParticipantPhotographyPermitted',
            inputValues.isCurrentPreschoolersParticipantPhotographyPermitted
          )}`
        : ''
    }`
  );
  participantPhotographiesPermitted.push(
    `${
      inputValues.participantTypeIds.includes(
        PARTICIPANT_TYPE_ID_GRADUATION_PRESCHOOLERS
      )
        ? `卒園卒業児童写り込み : ${toRadioBtnText(
            'isGraduationPreschoolersParticipantPhotographyPermitted',
            inputValues.isGraduationPreschoolersParticipantPhotographyPermitted
          )}`
        : ''
    }`
  );
  participantPhotographiesPermitted.push(
    `${
      inputValues.participantTypeIds.includes(PARTICIPANT_TYPE_ID_PARENT)
        ? `保護者写り込み : ${toRadioBtnText(
            'isParentParticipantPhotographyPermitted',
            inputValues.isParentParticipantPhotographyPermitted
          )}`
        : ''
    }`
  );
  participantPhotographiesPermitted.push(
    `${
      inputValues.participantTypeIds.includes(
        PARTICIPANT_TYPE_ID_EXTERNAL_INSTRUCTOR
      )
        ? `外部講師写り込み : ${toRadioBtnText(
            'isExternalInstructorParticipantPhotographyPermitted',
            inputValues.isExternalInstructorParticipantPhotographyPermitted
          )}`
        : ''
    }`
  );
  participantPhotographiesPermitted.push(
    `${
      inputValues.participantTypeIds.includes(PARTICIPANT_TYPE_ID_EXTERNAL)
        ? `地域・外部の方写り込み : ${toRadioBtnText(
            'isExternalParticipantPhotographyPermitted',
            inputValues.isExternalParticipantPhotographyPermitted
          )}`
        : ''
    }`
  );
  participantPhotographiesPermitted.push(
    `${
      inputValues.participantTypeIds.includes(PARTICIPANT_TYPE_ID_OTHER)
        ? `その他の方写り込み : ${toRadioBtnText(
            'isOtherParticipantPhotographyPermitted',
            inputValues.isOtherParticipantPhotographyPermitted
          )}`
        : ''
    }`
  );

  return stripNewLineForBlankTemplate`---◇  撮影対象 ◇---

■撮影対象者
${
  photography.eventTypeId !== EVENT_TYPE_ID_ENTRANCE_CEREMONY
    ? inputValues.photographySubjectTypeIds.length > 0
      ? constants.photographySubjectTypes
          .filter((type) =>
            inputValues.photographySubjectTypeIds.includes(type.key.toString())
          )
          .map((type) => type.value)
          .join(' , ')
      : '未確認'
    : `□新入児童
${constants.photographySubjectTypes
  .map((type) => {
    const count = inputValues.newChildrenPhotographySubjects.find(
      (subject) => subject.id === type.key
    )?.count;
    return `${type.value}(${count && !isNaN(count) ? count : 0}名)`;
  })
  .join(' , ')}`
}
${
  inputValues.photographySubjectRemark
    ? `□詳細\n${inputValues.photographySubjectRemark}`
    : ''
}

■撮影対象者以外の参加者
${inputValues.participantTypeIds?.length === 0 ? 'なし' : ''}
${
  inputValues.participantTypeIds?.length > 0
    ? constants.photographyParticipantType
        .filter((type) =>
          inputValues.participantTypeIds.includes(type.key.toString())
        )
        .map((type) => type.value)
        .join(' , ')
    : ''
}
${
  inputValues.participantTypeIds?.length > 0
    ? `□写り込み
${participantPhotographiesPermitted.filter((text) => text !== '').join(' , ')}
`
    : ''
}${
    inputValues.otherParticipantRemark !== ''
      ? `∟詳細 :
${inputValues.otherParticipantRemark}`
      : ''
  }
${
  inputValues.participantRemark !== ''
    ? `□詳細
${inputValues.participantRemark}`
    : ''
}

■参加人数 : ${photography.participantCount}名

■目印
□先生 : ${
    inputValues.subjectMarkForTeacher !== ''
      ? inputValues.subjectMarkForTeacher
      : 'なし'
  }
□園児 : ${
    inputValues.subjectMarkForPreschooler !== ''
      ? inputValues.subjectMarkForPreschooler
      : 'なし'
  }
□その他 : ${
    inputValues.subjectMarkForOther !== ''
      ? inputValues.subjectMarkForOther
      : 'なし'
  }
`;
};

export const getPhotographyPhotography = (
  inputValues: TFormInputs,
  photography: TPhotography,
  constants: TConstants
): string => {
  return stripNewLineForBlankTemplate`---◇ 撮影における情報 ◇---

■声かけ : ${toRadioBtnText('canPrompt', inputValues.canPrompt)}
${inputValues.promptRemark ? `◻︎詳細\n${inputValues.promptRemark}` : ''}

■登降時撮影 : ${toRadioBtnText(
    'hasArrivalAndDeparturePhoto',
    inputValues.hasArrivalAndDeparturePhoto
  )}
${
  inputValues.arrivalAndDeparturePhotoRemark
    ? `□詳細\n${inputValues.arrivalAndDeparturePhotoRemark}`
    : ''
}

■昼食撮影 : ${toRadioBtnText('hasLunchPhoto', inputValues.hasLunchPhoto)}
${inputValues.lunchPhotoRemark ? `□詳細\n${inputValues.lunchPhotoRemark}` : ''}

${
  shouldRenderSignboardPhoto(photography)
    ? stripNewLineForBlankTemplate`■看板前撮影 : ${toRadioBtnText(
        'hasSignboardPhoto',
        inputValues.hasSignboardPhoto
      )}
${
  inputValues.hasSignboardPhoto === 1 &&
  inputValues.signboardPhotoTimingTypeIds.length > 0
    ? `□撮影タイミング : ${inputValues.signboardPhotoTimingTypeIds
        .map((subject) =>
          toConstantsText(
            constants.signboardPhotoTimingTypes,
            parseInt(subject)
          )
        )
        .join(' , ')}`
    : ''
}
${
  inputValues.hasSignboardPhoto === 1
    ? `※${toConstantsText(
        constants.signboardPhotoTargetMessageTypes,
        inputValues.signboardPhotoTargetMessageTypeId
      )}`
    : ''
}
${
  inputValues.signboardPhotoRemark
    ? `□詳細\n${inputValues.signboardPhotoRemark}`
    : ''
}`
    : ''
}

■集合写真撮影 : ${
    photography.hasGroupPhoto !== undefined
      ? photography.hasGroupPhoto
        ? 'あり'
        : 'なし'
      : '未確認'
  }
${
  inputValues.groupPhotoSenNote ? `□詳細\n${inputValues.groupPhotoSenNote}` : ''
}

■撮影中の交通機関での移動 : ${toRadioBtnText(
    'hasTransportation',
    inputValues.hasTransportation
  )}
${
  inputValues.hasTransportation === 1
    ? `□カメラマンの同乗 : ${toRadioBtnText(
        'isPhotographerTransportationOnboardPermitted',
        inputValues.isPhotographerTransportationOnboardPermitted
      )}`
    : ''
}
${
  inputValues.hasTransportation === 1 && inputValues.transportationSenNote
    ? `□詳細\n${inputValues.transportationSenNote}`
    : ''
}

${
  shouldRenderSpecifiedPosition(photography)
    ? stripNewLineForBlankTemplate`■撮影立ち位置指定 : ${toRadioBtnText(
        'hasSpecifiedPosition',
        inputValues.hasSpecifiedPosition
      )}
${
  inputValues.hasSpecifiedPosition === 1 && inputValues.specifiedPositionSenNote
    ? `□詳細\n${inputValues.specifiedPositionSenNote}`
    : ''
}`
    : ''
}

■撮影NG対象 : ${toRadioBtnText(
    'hasNgPhotographySubject',
    inputValues.hasNgPhotographySubject
  )}
${
  inputValues.hasNgPhotographySubject === 1
    ? `□対象詳細 : ${inputValues.ngPhotographySubjectTypeIds
        .map((subject) =>
          toConstantsText(
            constants.ngPhotographySubjectTypes,
            parseInt(subject)
          )
        )
        .join(' , ')}`
    : ''
}
${
  inputValues.hasNgPhotographySubject === 1 &&
  inputValues.ngPhotographySubjectSenNote
    ? `□詳細\n${inputValues.ngPhotographySubjectSenNote}`
    : ''
}

${
  shouldRenderRest(photography)
    ? stripNewLineForBlankTemplate`■休憩(昼食) : ${toRadioBtnText(
        'hasRest',
        inputValues.hasRest
      )}
${
  inputValues.hasRest === 1
    ? `□時間 : ${
        inputValues.isRestTimeToBeDetermined
          ? '未確認'
          : `${inputValues.restTimeStartAt} ~ ${inputValues.restTimeEndAt}`
      }
□場所 : ${toConstantsText(
        constants.photographyRestPlaceTypes,
        inputValues.restPlaceTypeId
      )}
${
  inputValues.restPlaceTypeId === REST_PLACE_TYPE_ID_OTHER
    ? `∟詳細\n${inputValues.restPlaceOtherDescription}`
    : ''
}
${inputValues.restSenNote ? `□詳細\n${inputValues.restSenNote}` : ''}`
    : ''
}`
    : ''
}

${
  shouldRenderAlbumOption(photography)
    ? stripNewLineForBlankTemplate`■アルバム用撮影 : ${
        photography.hasAlbumPhoto !== undefined
          ? photography.hasAlbumPhoto
            ? 'あり'
            : 'なし'
          : '未確認'
      }
${
  inputValues.albumOptionSenNote
    ? `□詳細\n${inputValues.albumOptionSenNote}`
    : ''
}`
    : ''
}

■機材
${
  shouldRenderEquipments(inputValues, photography)
    ? stripNewLineForBlankTemplate`${getEquipmentText(
        inputValues,
        photography,
        constants
      )}
${
  shouldRenderTieredPlatform(photography)
    ? `□ひな壇の種類 : ${
        photography.tieredPlatformNameRemark !== null
          ? photography.tieredPlatformNameRemark
          : photography.tieredPlatformName
      }
□必要なセット数 : ${
        photography.tieredPlatformSetRemark !== null
          ? photography.tieredPlatformSetRemark
          : photography.tieredPlatformSetName
      }
${
  inputValues.tieredPlatformSenNote
    ? `□ひな壇の共有事項\n${inputValues.tieredPlatformSenNote}`
    : ''
}`
    : ''
}
${
  shouldRenderBackPaper(photography)
    ? `□バック紙保管場所 : ${photography.backPaperStoragePlace}`
    : ''
}
${
  shouldRenderOtherSpecialEquipment(photography)
    ? `□その他特殊機材詳細\n${photography.otherSpecialEquipmentRemark}`
    : ''
}
${
  shouldRenderStrobe(photography, inputValues)
    ? stripNewLineForBlankTemplate`□ストロボ使用(フラッシュ) : ${toRadioBtnText(
        'isStrobePermitted',
        inputValues.isStrobePermitted
      )}
${
  inputValues.strobeSenNote
    ? `□ストロボの共有事項\n${inputValues.strobeSenNote}`
    : ''
}`
    : ''
}`
    : '特になし'
}
${inputValues.equipmentSenNote ? `□詳細\n${inputValues.equipmentSenNote}` : ''}

■持ち物
${
  inputValues.belongingsTypeIds.length > 0
    ? inputValues.belongingsTypeIds
        .map((belongings) =>
          toConstantsText(
            constants.photographyBelongingsTypes,
            parseInt(belongings)
          )
        )
        .join(' , ')
    : '特になし'
}
${
  inputValues.belongingsTypeIds.length > 0 && inputValues.belongingsSenNote
    ? `□詳細\n${inputValues.belongingsSenNote}`
    : ''
}

${
  shouldRenderTripod(photography)
    ? stripNewLineForBlankTemplate`■三脚使用 : ${toRadioBtnText(
        'isTripodPermitted',
        inputValues.isTripodPermitted
      )}
${
  inputValues.isTripodPermitted === 1
    ? `□持参 : ${toRadioBtnText(
        'requireOwnTripod',
        inputValues.requireOwnTripod
      )}`
    : ''
}
${inputValues.tripodSenNote ? `□詳細\n${inputValues.tripodSenNote}` : ''}`
    : ''
}

${
  shouldRenderRentalStepladder(photography)
    ? `■脚立・踏み台の貸出 : ${toRadioBtnText(
        'isRentalStepladderAvailable',
        inputValues.isRentalStepladderAvailable
      )}`
    : ''
}

■団体からの撮影要望
${inputValues.societyRequest ? inputValues.societyRequest : 'なし'}
`;
};

const shouldRenderEquipments = (
  inputValues: TFormInputs,
  photography: TPhotography
): boolean => {
  const selectedEquipments = getSelectedEquipments(inputValues, photography);
  return selectedEquipments.length > 0;
};

const getEquipmentText = (
  inputValues: TFormInputs,
  photography: TPhotography,
  constants: TConstants
): string => {
  return `${getSelectedEquipments(inputValues, photography)
    .map((equipment) =>
      toConstantsText(constants.photographyEquipmentTypes, equipment)
    )
    .join(' , ')}`;
};

const getSelectedEquipments = (
  inputValues: TFormInputs,
  photography: TPhotography
): number[] => {
  const unchangeableKeys = getUnchangeableEquipmentTypes(photography);
  const filteredPhotographyEquipmentTypeIds =
    photography.equipmentTypeIds.filter((id) => unchangeableKeys.includes(id));
  const filteredInputValuesEquipmentTypeIds = inputValues.equipmentTypeIds
    .map((value) => parseInt(value))
    .filter((id) => !unchangeableKeys.includes(id));
  return [
    ...filteredPhotographyEquipmentTypeIds,
    ...filteredInputValuesEquipmentTypeIds,
  ];
};

export const rainyDayActionText = (inputValues: TFormInputs): string => {
  if (
    inputValues.rainyDayActionForPhotographerId ===
    RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_NO_CHANGE
  ) {
    return RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_TEXT_NO_CHANGE;
  } else if (
    inputValues.rainyDayActionForPhotographerId ===
    RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_HAS_CHANGE
  ) {
    return RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_TEXT_HAS_CHANGE;
  } else {
    return RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_TEXT_CANCEL;
  }
};

export const getDealingWithUnexpectedSituations = (
  inputValues: TFormInputs,
  photography: TPhotography,
  constants: TConstants
): string => {
  const filteredRainyPhotographingPlaces =
    inputValues.rainyPhotographingPlaces.filter(
      (place) => place.name || place.address
    );
  return stripNewLineForBlankTemplate`---◇ 不測の事態の対応情報 ◇---

■雨天時
□対応方針 : ${rainyDayActionText(inputValues)}
${
  inputValues.rainyDayActionForPhotographerId ===
  RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_HAS_CHANGE
    ? stripNewLineForBlankTemplate`□変更点
${inputValues.rainyChangePoint || 'なし'}
${
  inputValues.hasMeetingPlaceChangeWhenRainy === 1
    ? `□雨天時集合場所
${
  photography.rainyMeetingPlace === '園'
    ? '園'
    : photography.rainyMeetingPlaceMemo || '未確認'
}
※${HAS_MEETING_PLACE_CHANGE_WHEN_RAINY_TEXT_TRUE}`
    : ''
}
${
  filteredRainyPhotographingPlaces.length > 0 ||
  photography.rainyDayActionId === RAINY_DAY_ACTION_ID_NO_CANCEL
    ? `□雨天時撮影場所
${
  filteredRainyPhotographingPlaces.length > 0
    ? '\n' +
      filteredRainyPhotographingPlaces
        .map(
          (place) => `・施設名 : ${place.name || '未確認'}
・住所 : ${place.address || '未確認'}`
        )
        .join('\n\n')
    : photography.rainyPhotographingPlace === '園'
    ? '園'
    : photography.rainyPhotographingPlaceMemo ?? ''
}`
    : ''
}`
    : ''
}

■中止/撮影内容変更 連絡
□判断タイミング : ${toConstantsText(
    constants.cancellableDayTypes,
    inputValues.cancellableDayTypeId
  )}
${
  inputValues.cancellableDayTypeId === CANCELLABLE_DAY_TYPE_ID_THAT_DAY
    ? `□時間 : ${inputValues.cancelDecisionTime || '未確認'}`
    : ''
}
□連絡方法 : ${toConstantsText(
    constants.photographyCancelNotificationTypes,
    inputValues.cancelNotificationTypeId
  )}
${
  inputValues.cancelNotificationTypeId === CANCEL_NOTIFICATION_TYPE_ID_OTHER &&
  inputValues.cancelNotificationOtherDescription
    ? `∟詳細\n${inputValues.cancelNotificationOtherDescription}`
    : ''
}
※当日万が一、途中で中止となった場合はその場で営業もしくは弊社にご連絡ください。`;
};

export const getPhotographyCaution = (inputValues: TFormInputs): string => {
  return `---◇ 撮影時備考 ◇---

${inputValues.photographingCautionRemark || 'なし'}
`;
};

export const getSocietyContractContainer = (
  inputValues: TFormInputs,
  constants: TConstants
): string => {
  return ``;
};

export const getSummerFestival = (
  inputValues: TFormInputs,
  photography: TPhotography,
  constants: TConstants
): string => {
  return stripNewLineForBlankTemplate`■${photography.eventTypeName}情報
${
  inputValues.summerFestivalVenue
    ? `□実施場所
${inputValues.summerFestivalVenue}`
    : ''
}
□ブース : ${toRadioBtnText('hasStalls', inputValues.hasStalls)}
□ブース数 : ${suffixedNumber(inputValues.stallsCount, '箇所')}
□盆踊り撮影 : ${toRadioBtnText('hasBonDance', inputValues.hasBonDance)}
${
  inputValues.hasBonDance === 1
    ? stripNewLineForBlankTemplate`□踊り方 : ${toConstantsText(
        constants.bonDanceStyleTypes,
        inputValues.bonDanceStyleTypeId
      )}
□曲数 : ${suffixedNumber(inputValues.bonDanceSongCount, '曲')}
□曲の長さ : ${suffixedNumber(inputValues.bonDanceDurationMinutes, '分')}
${
  inputValues.bonDanceStyleTypeId === BON_DANCE_STYLE_TYPE_IN_CIRCLE
    ? stripNewLineForBlankTemplate`□輪の形態 : ${toConstantsText(
        constants.bonDanceCircleStyleTypes,
        inputValues.bonDanceCircleStyleTypeId
      )}
□輪内撮影 : ${toRadioBtnText(
        'isBonDancePhotographyPermittedInCircle',
        inputValues.isBonDancePhotographyPermittedInCircle
      )}`
    : ''
}`
    : ''
}
${
  inputValues.bonDanceStyleTypeId === BON_DANCE_STYLE_TYPE_OTHER &&
  inputValues.bonDanceStyleOtherDescription
    ? `∟詳細
${inputValues.bonDanceStyleOtherDescription || 'なし'}`
    : ''
}
□お神輿 : ${toRadioBtnText('hasPortableShrine', inputValues.hasPortableShrine)}
${
  inputValues.hasPortableShrine === 1
    ? `□ルート : ${toConstantsText(
        constants.portableShrineRouteTypes,
        inputValues.portableShrineRouteTypeId
      )}`
    : ''
}`;
};

export const getMochiPound = (
  inputValues: TFormInputs,
  constants: TConstants
): string => {
  return ``;
};

export const getChristmas = (
  inputValues: TFormInputs,
  photography: TPhotography,
  constants: TConstants
): string => {
  return stripNewLineForBlankTemplate`■${photography.eventTypeName}情報
□プレゼント受け渡し : ${toRadioBtnText('hasGift', inputValues.hasGift)}
${
  inputValues.hasGift === 1
    ? stripNewLineForBlankTemplate`□受け取り方法 : ${toConstantsText(
        constants.christmasGiftStyleTypes,
        inputValues.giftStyleTypeId
      )}
${
  inputValues.giftStyleTypeId === GIFT_STYLE_TYPE_OTHER &&
  inputValues.giftStyleOtherDescription
    ? `∟詳細
${inputValues.giftStyleOtherDescription}`
    : ''
}`
    : ''
}
□サンタさんの登場 : ${toRadioBtnText(
    'hasSantaArrival',
    inputValues.hasSantaArrival
  )}
${
  inputValues.hasSantaArrival === 1
    ? `□泣き顔撮影 : ${toRadioBtnText(
        'isCryFacePermittedForSantaArrival',
        inputValues.isCryFacePermittedForSantaArrival
      )}`
    : ''
}`;
};

export const getHalloween = (
  inputValues: TFormInputs,
  photography: TPhotography
): string => {
  return stripNewLineForBlankTemplate`■${photography.eventTypeName}情報
□カメラマンの仮装 : ${toRadioBtnText(
    'isPhotographerCostumeAvailable',
    inputValues.isPhotographerCostumeAvailable
  )}
${
  inputValues.isPhotographerCostumeAvailable === 1
    ? '※仮装は強制ではありませんが、何か身につけると喜ばれます。'
    : ''
}
□ファッションショー : ${toRadioBtnText(
    'hasFashionShow',
    inputValues.hasFashionShow
  )}
${
  inputValues.hasFashionShow === 1 && inputValues.fashionShowWalkStyle
    ? `□歩き方詳細\n${inputValues.fashionShowWalkStyle}`
    : ''
}`;
};

export const getOther = (
  inputValues: TFormInputs,
  constants: TConstants
): string => {
  const titleText = toConstantsText(
    constants.otherPhotographyTypes,
    inputValues.otherPhotographyTypeId
  );
  return stripNewLineForBlankTemplate`■${
    titleText !== 'デフォルト' ? titleText : 'その他'
  }情報
${getOtherDefaultText(inputValues, constants)}
${
  inputValues.otherPhotographyTypeId === OTHER_EVENT_TYPE_ID_CEREMONY
    ? getOtherCeremonyText(inputValues)
    : ''
}
${
  inputValues.otherPhotographyTypeId === OTHER_EVENT_TYPE_ID_BIRTHDAY_PARTY
    ? getOtherBirthdayPartyText(inputValues)
    : ''
}
${
  inputValues.otherPhotographyTypeId === OTHER_EVENT_TYPE_ID_FARMING_EXPERIENCE
    ? getOtherFarmingExperienceText(inputValues, constants)
    : ''
}
${
  inputValues.otherPhotographyTypeId === OTHER_EVENT_TYPE_ID_PLAYING_SHOP
    ? getOtherPlayingShopText(inputValues)
    : ''
}`;
};

export const getPool = (
  inputValues: TFormInputs,
  photography: TPhotography,
  constants: TConstants
): string => {
  return stripNewLineForBlankTemplate`■${photography.eventTypeName}情報
□服装 : ${toConstantsText(
    constants.poolClothesTypes,
    inputValues.poolClothesTypeId
  )}
${
  inputValues.poolClothesTypeId === POOL_CLOTHES_TYPE_ID_OTHER &&
  inputValues.poolClothesOtherDescription
    ? `∟詳細
${inputValues.poolClothesOtherDescription}`
    : ''
}`;
};

export const getDailyChildcare = (
  inputValues: TFormInputs,
  photography: TPhotography,
  constants: TConstants
): string => {
  return stripNewLineForBlankTemplate`■${photography.eventTypeName}情報
□実施場所 : ${toConstantsText(
    constants.photographyEnvironmentTypes,
    inputValues.photographyEnvironmentTypeId
  )}
□午睡撮影 : ${toRadioBtnText('hasNapPhoto', inputValues.hasNapPhoto)}`;
};

export const getSleepover = (
  inputValues: TFormInputs,
  constants: TConstants,
  photography: TPhotography
): string => {
  const photographingHotelPlaces = inputValues.photographerHotelPlaces.filter(
    (place) => place.name || place.address
  );
  return stripNewLineForBlankTemplate`■${photography.eventTypeName}情報
□宿泊 : ${toRadioBtnText('canSleepover', inputValues.canSleepover)}
${
  photography.canSleepover
    ? `□場所詳細
${
  photographingHotelPlaces.length > 0
    ? photographingHotelPlaces
        .map((place) => {
          return `・施設名 : ${place.name}
・住所 : ${place.address}`;
        })
        .join('\n\n')
    : `${
        photographingHotelPlaces.length === 0 && photography.sleepoverPlace
          ? photography.sleepoverPlace
          : ''
      }`
}`
    : ''
}
□寝顔撮影 : ${toRadioBtnText(
    'hasSleepingFacePhoto',
    inputValues.hasSleepingFacePhoto
  )}
${
  inputValues.isStrobePermitted === 1 && inputValues.hasSleepingFacePhoto === 1
    ? '※寝顔撮影のフラッシュについては、当日相談してください。'
    : ''
}
${
  photography.canSleepover
    ? Object.entries(
        Object.groupBy(inputValues.sleepoverMeals, (meal) => meal.day)
      )
        ?.map(([day, meals]) => {
          return `□${suffixedNumber(Number(day), '日目食事')}
${
  '・' +
  meals
    ?.map(
      (meal) =>
        constants.photographyMealTypes.find(
          (mealType) => mealType.key === meal.mealTypeId
        )?.value +
        ' : ' +
        toConstantsText(
          constants.photographyPreparingMealTypes,
          meal.preparingMealTypeId ?? null
        )
    )
    .join(' , ')
}`;
        })
        .join('\n')
    : ''
}
${
  inputValues.sleepoverSenNote
    ? `□補足事項\n${inputValues.sleepoverSenNote}`
    : ''
}`;
};

export const getEntranceCeremony = (
  inputValues: TFormInputs,
  photography: TPhotography,
  constants: TConstants
): string => {
  return stripNewLineForBlankTemplate`■${photography.eventTypeName}情報
□服装指定 : ${
    photography.hasDressCode === true
      ? 'スーツ（ネクタイ着用）'
      : photography.hasDressCode === false
      ? 'ジャケット着用（オフィスカジュアル）'
      : '未確認'
  }
□メダル授与 : ${toRadioBtnText('hasMedalAward', inputValues.hasMedalAward)}
${
  inputValues.hasMedalAward === 1
    ? stripNewLineForBlankTemplate`□授与方法 : ${toConstantsText(
        constants.medalAwardStyleTypes,
        inputValues.medalAwardStyleTypeId
      )}
${
  inputValues.medalAwardStyleTypeId === MEDAL_AWARD_STYLE_TYPE_OTHER &&
  inputValues.medalAwardOtherDescription
    ? `∟詳細\n${inputValues.medalAwardOtherDescription}`
    : ''
}`
    : ''
}
${getVideo(inputValues, constants)}`;
};

export const getGraduationCeremony = (
  inputValues: TFormInputs,
  photography: TPhotography,
  constants: TConstants
): string => {
  return stripNewLineForBlankTemplate`■${photography.eventTypeName}情報
□服装指定 : ${
    photography.hasDressCode === true
      ? 'スーツ（ネクタイ着用）'
      : photography.hasDressCode === false
      ? 'ジャケット着用（オフィスカジュアル）'
      : '未確認'
  }
□謝恩会 : ${
    photography.hasThankYouParty === true
      ? 'あり'
      : photography.hasThankYouParty === false
      ? 'なし'
      : '未確認'
  }
□証書授与 : ${toRadioBtnText('hasDiplomaAward', inputValues.hasDiplomaAward)}
${
  inputValues.hasDiplomaAward === 1
    ? stripNewLineForBlankTemplate`□授与時の撮影位置指定 : ${toConstantsText(
        [
          {
            key: SPECIFIED_DIPLOMA_AWARD_PHOTOGRAPHY_POSITION_TYPE_ID_NO_POSITION,
            value: '指定なし',
          },
          ...constants.diplomaAwardPositionTypes,
        ],
        inputValues.specifiedDiplomaAwardPhotographyPositionTypeId
      )}
${
  inputValues.specifiedDiplomaAwardPhotographyPositionTypeId ===
    SPECIFIED_DIPLOMA_AWARD_PHOTOGRAPHY_POSITION_TYPE_ID_OTHER &&
  inputValues.specifiedDiplomaAwardPhotographyPositionOtherDescription
    ? `∟詳細\n${inputValues.specifiedDiplomaAwardPhotographyPositionOtherDescription}`
    : ''
}
□授与方法 : ${toConstantsText(
        constants.diplomaAwardTypes,
        inputValues.diplomaAwardTypeId
      )}
${
  inputValues.diplomaAwardTypeId === DIPLOMA_AWARD_TYPE_ID_PER_PERSON ||
  inputValues.diplomaAwardTypeId === DIPLOMA_AWARD_TYPE_ID_REPRESENTATIVE
    ? `□児童合計人数 : ${suffixedNumber(inputValues.diplomaAwardCount, '名')}`
    : ''
}
${
  inputValues.diplomaAwardTypeId === DIPLOMA_AWARD_TYPE_ID_OTHER &&
  inputValues.diplomaAwardOtherDescription
    ? `∟詳細\n${inputValues.diplomaAwardOtherDescription}`
    : ''
}`
    : ''
}
${getVideo(inputValues, constants)}`;
};

export const getSportsDay = (
  inputValues: TFormInputs,
  photography: TPhotography,
  constants: TConstants
): string => {
  return stripNewLineForBlankTemplate`■${photography.eventTypeName}情報
□撮影環境 : ${photography.photographingAmbientName ?? '未確認'}
□トラック内撮影 : ${toRadioBtnText(
    'isPhotographyInsideTrackPermitted',
    inputValues.isPhotographyInsideTrackPermitted
  )}
□撮影不要の演目 : ${toRadioBtnText(
    'hasSkippingPhotography',
    inputValues.hasSkippingPhotography
  )}
${
  inputValues.hasSkippingPhotography === 1 &&
  inputValues.skippingPhotographyRemark
    ? `□詳細\n${inputValues.skippingPhotographyRemark}`
    : ''
}
□保護者の開場時間 : ${inputValues.openAt ? inputValues.openAt : '未確認'}
□開会式の開始時間 : ${
    inputValues.openingCeremonyStartAt
      ? inputValues.openingCeremonyStartAt
      : '未確認'
  }
${getVideo(inputValues, constants)}`;
};

export const getRecital = (
  inputValues: TFormInputs,
  photography: TPhotography,
  constants: TConstants
): string => {
  return stripNewLineForBlankTemplate`■${photography.eventTypeName}情報
□会場詳細 : ${toRadioBtnText(
    'hasVenueEnvironment',
    inputValues.hasVenueEnvironment
  )}
${
  inputValues.hasVenueEnvironment === 1
    ? stripNewLineForBlankTemplate`□保護者客席 : ${toRadioBtnText(
        'hasParentSeats',
        inputValues.hasParentSeats
      )}
${
  inputValues.hasParentSeats === 1
    ? stripNewLineForBlankTemplate`□客席詳細 : ${
        inputValues.parentSeatTypeIds.length > 0
          ? toConstantsText(
              constants.parentSeatTypes,
              toNumberArray(inputValues.parentSeatTypeIds)
            )
          : '未確認'
      }
${
  inputValues.parentSeatTypeIds.includes(PARENT_SEAT_TYPE_ID_OTHER) &&
  inputValues.parentSeatOtherDescription
    ? `∟詳細\n${inputValues.parentSeatOtherDescription}`
    : ''
}`
    : ''
}
□上演中の照明 : ${toConstantsText(
        constants.lightingTypes,
        inputValues.lightingTypeId
      )}
${
  inputValues.lightingTypeId === LIGHTING_TYPE_ID_OTHER &&
  inputValues.lightingOtherDescription
    ? `∟詳細\n${inputValues.lightingOtherDescription}`
    : ''
}
${inputValues.venueSize ? `□広さ詳細\n${inputValues.venueSize}` : ''}`
    : ''
}
□動画撮影 : ${
    photography.hasVideo === true
      ? 'あり'
      : photography.hasVideo === false
      ? 'なし'
      : '未確認'
  }
${
  photography.hasVideo === true
    ? stripNewLineForBlankTemplate`□撮影者 : ${toConstantsText(
        constants.videographerTypes,
        inputValues.videographerTypeId
      )}
${
  inputValues.videographerTypeId !== ID_UNCONFIRMED && inputValues.videoRemark
    ? `□詳細\n${inputValues.videoRemark}`
    : ''
}`
    : ''
}`;
};

export const getThrowingBeans = (
  inputValues: TFormInputs,
  photography: TPhotography
): string => {
  return stripNewLineForBlankTemplate`■${photography.eventTypeName}情報
□鬼の登場 : ${toRadioBtnText('hasOgreArrival', inputValues.hasOgreArrival)}
${
  inputValues.hasOgreArrival === 1
    ? `□泣き顔撮影 : ${toRadioBtnText(
        'isCryFacePermittedForOgreArrival',
        inputValues.isCryFacePermittedForOgreArrival
      )}`
    : ''
}`;
};

const getVideo = (inputValues: TFormInputs, constants: TConstants): string => {
  return stripNewLineForBlankTemplate`□動画撮影 : ${toRadioBtnText(
    'hasVideo',
    inputValues.hasVideo
  )}
${
  inputValues.hasVideo === 1
    ? `□撮影者 : ${toConstantsText(
        constants.videographerTypes,
        inputValues.videographerTypeId
      )}`
    : ''
}
${
  inputValues.hasVideo === 1 &&
  inputValues.videographerTypeId !== ID_UNCONFIRMED &&
  inputValues.videoRemark
    ? `□詳細\n${inputValues.videoRemark}`
    : ''
}`;
};
const getOtherDefaultText = (
  inputValues: TFormInputs,
  constants: TConstants
) => {
  return stripNewLineForBlankTemplate`□実施場所 : ${toConstantsText(
    constants.photographyEnvironmentTypes,
    inputValues.photographyEnvironmentTypeId
  )}
□午睡撮影 : ${toRadioBtnText('hasNapPhoto', inputValues.hasNapPhoto)}
`;
};

const getOtherCeremonyText = (inputValues: TFormInputs): string => {
  return `□正装 : ${toRadioBtnText('hasDressCode', inputValues.hasDressCode)}`;
};

const getOtherBirthdayPartyText = (inputValues: TFormInputs): string => {
  return `□誕生児個人写真 : ${toRadioBtnText(
    'hasBirthdayChildrenPhoto',
    inputValues.hasBirthdayChildrenPhoto
  )}
□誕生児人数 : ${suffixedNumber(inputValues.birthdayChildrenCount, '名')}`;
};

const getOtherFarmingExperienceText = (
  inputValues: TFormInputs,
  constants: TConstants
): string => {
  return `□堀り方 : ${toConstantsText(
    constants.farmingExperienceDiggingMethodTypes,
    inputValues.diggingMethodTypeId
  )}
${
  inputValues.diggingMethodTypeId ===
    FARMING_EXPERIENCE_DIGGING_METHOD_OTHER_TYPE &&
  inputValues.diggingMethodOtherDescription
    ? `∟詳細\n${inputValues.diggingMethodOtherDescription}`
    : ''
}`;
};

const getOtherPlayingShopText = (inputValues: TFormInputs): string => {
  return stripNewLineForBlankTemplate`□買い手・売り手
${
  inputValues.buyersSellersRemark
    ? inputValues.buyersSellersRemark
    : '買い手・売り手の入れ替えタイミングは当日ご確認ください'
}
□店舗数 : ${suffixedNumber(inputValues.shopCount, '店舗')}
${
  inputValues.shopPhotographyVenue
    ? `□実施場所(会場)\n${inputValues.shopPhotographyVenue}`
    : ''
}`;
};

import { useState } from 'react';
import { TPhotography } from '../types';
import styles from './style.module.scss';
import clsx from 'clsx';
import { TFormInputs } from '../types';
import { useFormContext } from 'react-hook-form';
import {
  checkListItems,
  TCheckListChild,
  TCheckListParent,
} from './checkListItem';

const CheckListChild: React.FC<{
  item: TCheckListChild;
  photography: TPhotography;
}> = ({ item, photography }) => {
  const methods = useFormContext<TFormInputs>();
  const handleClick = () => {
    const element = document.getElementById(item.scrollTarget);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  if (!item.visible(photography, methods.watch())) {
    return null;
  }
  if ('checked' in item && !item.checked(methods.watch())) {
    return (
      <div className={styles.uncheckedChildItem} onClick={handleClick}>
        !{item.name}
      </div>
    );
  }
  if ('star' in item) {
    return (
      <div className={styles.starChildItem} onClick={handleClick}>
        ★{item.name}
      </div>
    );
  }
  return (
    <div className={styles.checkedChildItem} onClick={handleClick}>
      {item.name}
    </div>
  );
};
const CheckListParent: React.FC<{
  item: TCheckListParent;
  photography: TPhotography;
}> = ({ item, photography }) => {
  // ここには以下のうちチェックがついてない奴がいたら赤字になるロジックがいる
  // ここにchildren側と同じくscrollするロジックがいる
  return (
    <>
      <div>{item.name}</div>
      {item.children.map((child) => (
        <CheckListChild
          item={child}
          photography={photography}
          key={child.scrollTarget}
        />
      ))}
    </>
  );
};

const CheckListItems: React.FC<{
  photography: TPhotography;
  handleClose: () => void;
}> = ({ photography, handleClose }) => {
  return (
    <div className={clsx(styles.checkListOverlay)}>
      <div>チェックリスト</div>
      {checkListItems.map((item) => (
        <CheckListParent
          item={item}
          photography={photography}
          key={item.scrollTarget}
        />
      ))}
      <button type="button" onClick={() => handleClose()}>
        閉じる
      </button>
    </div>
  );
};

export const CheckList: React.FC<{ photography: TPhotography }> = ({
  photography,
}) => {
  const [displayed, setDisplayed] = useState(false);
  return (
    <>
      {displayed && (
        <CheckListItems
          photography={photography}
          handleClose={() => setDisplayed(false)}
        />
      )}
      <button type="button" onClick={() => setDisplayed(!displayed)}>
        チェックリストを確認する
      </button>
    </>
  );
};

import {
  Checkboxes,
  RadioBoxes,
  TextArea,
} from '@/components/shared/Form/Inputs';
import {
  TConstants,
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { TChoice, TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { UseFormReturn } from 'react-hook-form';
import { useEffect } from 'react';
import {
  TRANSPORTATION_TYPE_BICYCLE,
  TRANSPORTATION_TYPE_CAR,
  TRANSPORTATION_TYPE_MOTORCYCLE,
} from '@/components/pages/Events/Show/Photography/const';

type MeetingPlaceProps = {
  methods: UseFormReturn<TFormInputs>;
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
};

export const MeetingPlace: React.FC<MeetingPlaceProps> = ({
  methods,
  photography,
  constants,
  validator,
}) => {
  const PRESENT_CHOICES: TChoice[] = [
    { key: 1, value: 'あり' },
    { key: 0, value: 'なし' },
  ];

  const watchedMeetingPlaceTransportationTypeIds = methods.watch(
    'meetingPlaceTransportationTypeIds'
  );

  const isCarSelected =
    watchedMeetingPlaceTransportationTypeIds?.includes(
      TRANSPORTATION_TYPE_CAR
    ) ||
    watchedMeetingPlaceTransportationTypeIds?.includes(
      TRANSPORTATION_TYPE_MOTORCYCLE
    );

  const isBicycleSelected =
    watchedMeetingPlaceTransportationTypeIds?.includes(
      TRANSPORTATION_TYPE_BICYCLE
    ) ||
    watchedMeetingPlaceTransportationTypeIds?.includes(
      TRANSPORTATION_TYPE_MOTORCYCLE
    );

  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (name === 'meetingPlaceTransportationTypeIds' && type === 'change') {
        if (
          !value.meetingPlaceTransportationTypeIds?.includes(
            TRANSPORTATION_TYPE_CAR
          ) &&
          !value.meetingPlaceTransportationTypeIds?.includes(
            TRANSPORTATION_TYPE_MOTORCYCLE
          )
        ) {
          methods.setValue('hasCarParkingLotAtMeetingPlace', 0);
          methods.setValue('meetingPlaceCarParkingLotRemark', '');
        }
        if (
          !value.meetingPlaceTransportationTypeIds?.includes(
            TRANSPORTATION_TYPE_BICYCLE
          ) &&
          !value.meetingPlaceTransportationTypeIds?.includes(
            TRANSPORTATION_TYPE_MOTORCYCLE
          )
        ) {
          methods.setValue('hasBicycleParkingLotAtMeetingPlace', 0);
          methods.setValue('meetingPlaceBicycleParkingLotRemark', '');
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [methods]);

  return (
    <div className="c-frame">
      <div className="c-section_title">集合場所</div>
      <hr className="u-line_plane" />
      <PhotographyAccordion title="場所詳細">
        <div>{photography.meetingPlace ?? '園'}</div>
        {photography.meetingPlaceMemo && (
          <>
            <div className="t-bgBox_gray">{photography.meetingPlaceMemo}</div>
            <div className="t-textColor_sub">
              ※申請サマリの登録情報を表示しています。
            </div>
          </>
        )}
      </PhotographyAccordion>
      <PhotographyAccordion title="行き方">
        <TextArea
          name="meetingPlaceAccess"
          placeholder="行き方を入力してください"
          rows={3}
          validator={validator}
        />
      </PhotographyAccordion>
      <PhotographyAccordion title="交通機関">
        <Checkboxes
          name="meetingPlaceTransportationTypeIds"
          validator={validator}
          choices={constants.photographyTransportationTypes}
        />
      </PhotographyAccordion>
      {isCarSelected && (
        <PhotographyAccordion title="駐車場">
          <RadioBoxes
            name="hasCarParkingLotAtMeetingPlace"
            validator={validator}
            choices={PRESENT_CHOICES}
          />
          <div className="t-textColor_sub">詳細</div>
          <TextArea
            name="meetingPlaceCarParkingLotRemark"
            placeholder="詳細を入力してください"
            rows={3}
            validator={validator}
          />
        </PhotographyAccordion>
      )}
      {isBicycleSelected && (
        <PhotographyAccordion title="駐輪場">
          <RadioBoxes
            name="hasBicycleParkingLotAtMeetingPlace"
            validator={validator}
            choices={PRESENT_CHOICES}
          />
          <div className="t-textColor_sub">詳細</div>
          <TextArea
            name="meetingPlaceBicycleParkingLotRemark"
            placeholder="詳細を入力してください"
            rows={3}
            validator={validator}
          />
        </PhotographyAccordion>
      )}
    </div>
  );
};

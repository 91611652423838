import {
  EVENT_TYPE_ID_ENTRANCE_CEREMONY,
  EVENT_TYPE_ID_FIELD_TRIPS,
  EVENT_TYPE_ID_GRADUATION_CEREMONY,
  EVENT_TYPE_ID_RECITAL,
  EVENT_TYPE_ID_RECITAL_REHEARSAL,
  EVENT_TYPE_ID_SLEEPOVER,
  EVENT_TYPE_ID_SPORTS_DAY,
  EVENT_TYPE_ID_SPORTS_DAY_REHEARSAL,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BACK_PAPER,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_OTHER_SPECIAL_EQUIPMENT,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_STROBE,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_TIERED_PLATFORM,
  REQUEST_OPTION_ID_ALBUM,
} from '@/components/pages/Events/Show/Photography/const';
import {
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';

export const shouldRenderSpecifiedPosition = (
  photography: TPhotography
): boolean =>
  [
    EVENT_TYPE_ID_ENTRANCE_CEREMONY,
    EVENT_TYPE_ID_GRADUATION_CEREMONY,
    EVENT_TYPE_ID_SPORTS_DAY,
    EVENT_TYPE_ID_SPORTS_DAY_REHEARSAL,
    EVENT_TYPE_ID_RECITAL,
    EVENT_TYPE_ID_RECITAL_REHEARSAL,
  ].includes(photography.eventTypeId);

export const shouldRenderSignboardPhoto = (
  photography: TPhotography
): boolean =>
  [EVENT_TYPE_ID_ENTRANCE_CEREMONY, EVENT_TYPE_ID_GRADUATION_CEREMONY].includes(
    photography.eventTypeId
  );

export const shouldRenderTripod = (photography: TPhotography): boolean =>
  [
    EVENT_TYPE_ID_ENTRANCE_CEREMONY,
    EVENT_TYPE_ID_GRADUATION_CEREMONY,
    EVENT_TYPE_ID_RECITAL,
    EVENT_TYPE_ID_RECITAL_REHEARSAL,
  ].includes(photography.eventTypeId) ||
  photography.requestOptions.includes(REQUEST_OPTION_ID_ALBUM);

export const shouldRenderRentalStepladder = (
  photography: TPhotography
): boolean =>
  [
    EVENT_TYPE_ID_SPORTS_DAY,
    EVENT_TYPE_ID_SPORTS_DAY_REHEARSAL,
    EVENT_TYPE_ID_RECITAL,
    EVENT_TYPE_ID_RECITAL_REHEARSAL,
  ].includes(photography.eventTypeId);

export const shouldRenderAlbumOption = (photography: TPhotography): boolean =>
  photography.requestOptions.includes(REQUEST_OPTION_ID_ALBUM);

export const shouldRenderEquipment = (photography: TPhotography): boolean =>
  [
    EVENT_TYPE_ID_ENTRANCE_CEREMONY,
    EVENT_TYPE_ID_GRADUATION_CEREMONY,
    EVENT_TYPE_ID_RECITAL,
    EVENT_TYPE_ID_RECITAL_REHEARSAL,
    EVENT_TYPE_ID_SLEEPOVER,
  ].includes(photography.eventTypeId) ||
  photography.requestOptions.includes(REQUEST_OPTION_ID_ALBUM);

export const shouldRenderRest = (photography: TPhotography): boolean => {
  dayjs.extend(CustomParseFormat);
  return (
    dayjs(photography.photographingEndTime, 'HH:mm').diff(
      dayjs(photography.photographingStartTime, 'HH:mm'),
      'hour'
    ) >= 6 &&
    (photography.eventTypeId !== EVENT_TYPE_ID_SLEEPOVER ||
      (photography.eventTypeId === EVENT_TYPE_ID_SLEEPOVER &&
        photography.canSleepover === false))
  );
};

export const shouldRenderTieredPlatform = (
  photography: TPhotography
): boolean => {
  return photography.equipmentTypeIds.includes(
    PHOTOGRAPHY_EQUIPMENT_TYPE_ID_TIERED_PLATFORM
  );
};

export const shouldRenderBackPaper = (photography: TPhotography): boolean => {
  return photography.equipmentTypeIds.includes(
    PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BACK_PAPER
  );
};

export const shouldRenderOtherSpecialEquipment = (
  photography: TPhotography
): boolean => {
  return photography.equipmentTypeIds.includes(
    PHOTOGRAPHY_EQUIPMENT_TYPE_ID_OTHER_SPECIAL_EQUIPMENT
  );
};

export const shouldRenderStrobe = (
  photography: TPhotography,
  inputValues: TFormInputs
): boolean => {
  return (
    inputValues.equipmentTypeIds.includes(
      String(PHOTOGRAPHY_EQUIPMENT_TYPE_ID_STROBE)
    ) ||
    ((photography.eventTypeId === EVENT_TYPE_ID_RECITAL ||
      photography.eventTypeId === EVENT_TYPE_ID_RECITAL_REHEARSAL) &&
      (photography.isStrobePermitted ?? false))
  );
};

export const shouldRenderPhotographerExpense = (
  photography: TPhotography
): boolean => {
  return (
    photography.eventTypeId === EVENT_TYPE_ID_FIELD_TRIPS ||
    photography.eventTypeId === EVENT_TYPE_ID_SLEEPOVER
  );
};

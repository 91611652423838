import {
  TConstants,
  TFormInputs,
} from '@/components/pages/Events/Show/Photography/types';
import { UseFormReturn } from 'react-hook-form';
import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import {
  CheckBox,
  RadioBoxes,
  TextArea,
  TextInput,
  TimeInput,
} from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED } from '@/components/pages/Events/Show/Photography/const';
import React, { useEffect } from 'react';
import styles from './index.module.scss';
import { Alert } from '@/components/shared/Alert';

type RestProps = {
  methods: UseFormReturn<TFormInputs>;
  constants: TConstants;
  validator: TValidator;
};

export const Rest: React.FC<RestProps> = ({
  methods,
  constants,
  validator,
}) => {
  const REST_PLACE_TYPE_OTHER = 3;
  const { watch, setValue } = methods;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasRest' && type === 'change') {
        if (value.hasRest !== 1) {
          setValue('isRestTimeToBeDetermined', true);
          setValue('restTimeStartAt', '');
          setValue('restTimeEndAt', '');
          setValue('restPlaceTypeId', ID_UNCONFIRMED);
          setValue('restPlaceOtherDescription', '');
          setValue('restSenNote', '');
        }
      }
      if (name === 'isRestTimeToBeDetermined' && type === 'change') {
        if (value.isRestTimeToBeDetermined) {
          setValue('restTimeStartAt', '');
          setValue('restTimeEndAt', '');
        }
        if (
          !value.isRestTimeToBeDetermined &&
          value.restTimeStartAt === '' &&
          value.restTimeEndAt === ''
        ) {
          setValue('isRestTimeToBeDetermined', true);
        }
      }
      if (
        (name === 'restTimeStartAt' || name === 'restTimeEndAt') &&
        type === 'change'
      ) {
        if (value.restTimeStartAt !== '' || value.restTimeEndAt !== '') {
          setValue('isRestTimeToBeDetermined', false);
        }
      }
      if (name === 'restPlaceTypeId' && type === 'change') {
        if (value.restPlaceTypeId !== REST_PLACE_TYPE_OTHER) {
          setValue('restPlaceOtherDescription', '');
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <PhotographyAccordion title="休憩（昼食）">
      <RadioBoxes
        name="hasRest"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: 'あり' },
          { key: 0, value: 'なし' },
        ]}
      />
      {watch('hasRest') === 1 && (
        <>
          <div className="t-textColor_sub">時間</div>
          <div className={styles.restTimeWrap}>
            <CheckBox
              name="isRestTimeToBeDetermined"
              validator={validator}
              label="時間未定"
            />
            <TimeInput name="restTimeStartAt" validator={validator} />
            <span className={styles.timeBetweenText}>〜</span>
            <TimeInput name="restTimeEndAt" validator={validator} />
          </div>
          <Alert type="error" title="休憩時間について">
            <ul>
              <li>・拘束時間が6時間を超え、8時間以下の場合：45分の休憩付与</li>
              <li>・拘束時間が8時間を超える場合：少なくとも1時間の休憩付与</li>
            </ul>
          </Alert>
          <div className="t-textColor_sub">場所</div>
          <RadioBoxes
            name="restPlaceTypeId"
            validator={validator}
            choices={[
              { key: ID_UNCONFIRMED, value: '未確認' },
              ...constants.photographyRestPlaceTypes,
            ]}
          />
          {watch('restPlaceTypeId') === REST_PLACE_TYPE_OTHER && (
            <>
              <div className="t-textColor_sub">その他詳細</div>
              <TextInput
                name="restPlaceOtherDescription"
                validator={validator}
              />
            </>
          )}
          <div className="t-textColor_sub">詳細</div>
          <TextArea
            name="restSenNote"
            validator={validator}
            placeholder="休憩の詳細を入力してください"
          />
        </>
      )}
    </PhotographyAccordion>
  );
};

import {
  TConstants,
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { UseFormReturn } from 'react-hook-form';
import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { RadioBoxes, TextInput } from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED } from '@/components/pages/Events/Show/Photography/const';
import { useEffect } from 'react';

type PoolProps = {
  photography: TPhotography;
  methods: UseFormReturn<TFormInputs>;
  constants: TConstants;
  validator: TValidator;
};

export const Pool: React.FC<PoolProps> = ({
  photography,
  methods,
  constants,
  validator,
}) => {
  const POOL_CLOTHES_TYPE_OTHER = 3;
  const { watch, setValue } = methods;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'poolClothesTypeId' && type === 'change') {
        if (value.poolClothesTypeId !== POOL_CLOTHES_TYPE_OTHER) {
          setValue('poolClothesOtherDescription', '');
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <PhotographyAccordion title={`${photography.eventTypeName}情報`}>
      <div className="t-textColor_sub">服装</div>
      <RadioBoxes
        name="poolClothesTypeId"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          ...constants.poolClothesTypes,
        ]}
      />
      {watch('poolClothesTypeId') === POOL_CLOTHES_TYPE_OTHER && (
        <>
          <div className="t-textColor_sub">その他詳細</div>
          <TextInput name="poolClothesOtherDescription" validator={validator} />
        </>
      )}
    </PhotographyAccordion>
  );
};

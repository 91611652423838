import React from 'react';
import { MailLog } from '../../types';
import { ContentPanel } from '@/components/shared/ContentPanel';
import { Table } from '@/components/shared/Table';
import { useResultPanel } from './hooks';

type Props = {
  logs?: MailLog[];
};

const columns = [
  'time',
  'email',
  'event',
  'response',
  'reason',
  'status',
  'ip',
  'sg event id',
];
const columnsParam = [
  '200px',
  'minmax(200px, 1fr)',
  '100px',
  'minmax(100px, 1fr)',
  'minmax(200px, 1fr)',
  '100px',
  '110px',
  'minmax(200px, 1fr)',
];

export const ResultPanel = React.memo<Props>(function ResultPanel({ logs }) {
  const { rows } = useResultPanel({ logs });
  if (!rows || rows?.length === 0) {
    return null;
  }

  return (
    <ContentPanel marginTop="large">
      <Table columns={columns} rows={rows} gridTemplateColumns={columnsParam} />
    </ContentPanel>
  );
});

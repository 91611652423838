import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { RadioBoxes } from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED } from '@/components/pages/Events/Show/Photography/const';
import { TValidator } from '@/components/shared/Form/types';
import { UseFormReturn } from 'react-hook-form';
import {
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { useEffect } from 'react';

export const ThrowingBeans: React.FC<{
  photography: TPhotography;
  methods: UseFormReturn<TFormInputs>;
  validator: TValidator;
}> = ({ photography, methods, validator }) => {
  const { watch, setValue } = methods;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasOgreArrival' && type === 'change') {
        if (value.hasOgreArrival !== 1) {
          setValue('isCryFacePermittedForOgreArrival', ID_UNCONFIRMED);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <>
      <PhotographyAccordion title={`${photography.eventTypeName}情報`}>
        <div className="t-textColor_sub">鬼の登場</div>
        <RadioBoxes
          name="hasOgreArrival"
          validator={validator}
          choices={[
            { key: ID_UNCONFIRMED, value: '未確認' },
            { key: 1, value: 'あり' },
            { key: 0, value: 'なし' },
          ]}
        />
        {watch('hasOgreArrival') === 1 && (
          <>
            <hr className="u-line_plane" />
            <div className="t-textColor_sub">泣き顔撮影</div>
            <RadioBoxes
              name="isCryFacePermittedForOgreArrival"
              validator={validator}
              choices={[
                { key: ID_UNCONFIRMED, value: '未確認' },
                { key: 1, value: '可能' },
                { key: 0, value: '不可' },
              ]}
            />
          </>
        )}
      </PhotographyAccordion>
    </>
  );
};

import { TPhotography } from '@/components/pages/Events/Show/Photography/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { Alert } from '@/components/shared/Alert';
import { useParams } from 'react-router';
import { ownerUrl } from '@/ts/url';

export const EventType: React.FC<{ photography: TPhotography }> = ({
  photography,
}) => {
  const { eventId } = useParams<{ eventId: string }>();
  return (
    <PhotographyAccordion title="イベント種類">
      <div>{photography.eventTypeName}</div>
      <Alert type="error" title="イベント種類の変更について">
        <p>
          申請サマリから変更可能です。選択しているイベント種類によって登録可能な項目が変わりますので、変更する際はご留意ください。
        </p>
        <a
          className="c-btn c-btn_middle_wide c-btn_black"
          href={ownerUrl(`events/requests/${eventId}`)}
          target="_blank"
          rel="noreferrer"
        >
          申請サマリ編集
        </a>
      </Alert>
    </PhotographyAccordion>
  );
};

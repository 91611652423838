import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '../../Shared/Accordion/App';
import { TConstants, TPhotography, TFormInputs } from '../../types';
import {
  NullableNumberInput,
  RadioBoxes,
  TextInput,
} from '@/components/shared/Form/Inputs';
import {
  DIPLOMA_AWARD_TYPE_ID_OTHER,
  DIPLOMA_AWARD_TYPE_ID_PER_PERSON,
  DIPLOMA_AWARD_TYPE_ID_REPRESENTATIVE,
  ID_UNCONFIRMED,
  SPECIFIED_DIPLOMA_AWARD_PHOTOGRAPHY_POSITION_TYPE_ID_NO_POSITION,
  SPECIFIED_DIPLOMA_AWARD_PHOTOGRAPHY_POSITION_TYPE_ID_OTHER,
} from '../../const';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import styles from './style.module.scss';
import clsx from 'clsx';
import { Video } from '../Video';
import { Alert } from '@/components/shared/Alert';
import { ownerUrl } from '@/ts/url';
import { useParams } from 'react-router-dom';

export const GraduationCeremony: React.FC<{
  photography: TPhotography;
  validator: TValidator;
  constants: TConstants;
}> = ({ photography, constants, validator }) => {
  const { eventId } = useParams<{ eventId: string }>();
  const { watch, setValue } = useFormContext<TFormInputs>();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasDiplomaAward' && type === 'change') {
        if (value.hasDiplomaAward !== 1) {
          setValue(
            'specifiedDiplomaAwardPhotographyPositionTypeId',
            ID_UNCONFIRMED
          );
          setValue(
            'specifiedDiplomaAwardPhotographyPositionOtherDescription',
            ''
          );
          setValue('diplomaAwardTypeId', ID_UNCONFIRMED);
          setValue('diplomaAwardCount', null);
          setValue('diplomaAwardOtherDescription', '');
        }
      }
      if (
        name === 'specifiedDiplomaAwardPhotographyPositionTypeId' &&
        type === 'change'
      ) {
        if (
          value.specifiedDiplomaAwardPhotographyPositionTypeId !==
          SPECIFIED_DIPLOMA_AWARD_PHOTOGRAPHY_POSITION_TYPE_ID_OTHER
        ) {
          setValue(
            'specifiedDiplomaAwardPhotographyPositionOtherDescription',
            ''
          );
        }
      }
      if (name === 'diplomaAwardTypeId' && type === 'change') {
        if (value.diplomaAwardTypeId !== DIPLOMA_AWARD_TYPE_ID_OTHER) {
          setValue('diplomaAwardOtherDescription', '');
        }
        if (
          value.diplomaAwardTypeId !== DIPLOMA_AWARD_TYPE_ID_PER_PERSON &&
          value.diplomaAwardTypeId !== DIPLOMA_AWARD_TYPE_ID_REPRESENTATIVE
        ) {
          setValue('diplomaAwardCount', null);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);
  return (
    <PhotographyAccordion title={`${photography.eventTypeName}情報`}>
      <div className="t-textColor_sub">正装</div>
      <div>{photography.hasDressCode ? '必要' : '不要'}</div>
      <div className="u-fz_s t-textColor_sub">
        ※申請サマリの登録情報を表示しています
      </div>
      <div className="t-textColor_sub">謝恩会</div>
      <div>
        {photography.hasThankYouParty === true
          ? 'あり'
          : photography.hasThankYouParty === false
          ? 'なし'
          : '未定'}
      </div>
      <div className="u-fz_s t-textColor_sub">
        ※申請サマリの登録情報を表示しています
      </div>
      <Alert type="error" title="正装/謝恩会について">
        <p>
          申請サマリの項目のため、申請サマリ編集から対応をお願いいたします。撮影情報をコピーする際は「服装指定」として下記に置換されます。
          <br />
          ・正装「必要」→スーツ（ネクタイ着用）
          <br />
          ・正装「不要」→ジャケット着用（オフィスカジュアル）
        </p>
        <a
          className="c-btn c-btn_middle_wide c-btn_black u-mgt_s"
          href={ownerUrl(`events/requests/${eventId}`)}
          target="_blank"
          rel="noreferrer"
        >
          申請サマリ編集
        </a>
      </Alert>
      <hr className="u-line_plane" />
      <div className="t-textColor_sub">証書授与</div>
      <RadioBoxes
        name="hasDiplomaAward"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: 'あり' },
          { key: 0, value: 'なし' },
        ]}
      />
      {watch('hasDiplomaAward') === 1 && (
        <>
          <div className="t-textColor_sub">授与時の撮影位置指定</div>
          <RadioBoxes
            name="specifiedDiplomaAwardPhotographyPositionTypeId"
            validator={validator}
            choices={[
              { key: ID_UNCONFIRMED, value: '未確認' },
              {
                key: SPECIFIED_DIPLOMA_AWARD_PHOTOGRAPHY_POSITION_TYPE_ID_NO_POSITION,
                value: '指定なし',
              },
              ...constants.diplomaAwardPositionTypes,
            ]}
          />
          {watch('specifiedDiplomaAwardPhotographyPositionTypeId') ===
            SPECIFIED_DIPLOMA_AWARD_PHOTOGRAPHY_POSITION_TYPE_ID_OTHER && (
            <>
              <div className="t-textColor_sub">その他詳細</div>
              <TextInput
                name="specifiedDiplomaAwardPhotographyPositionOtherDescription"
                validator={validator}
              />
            </>
          )}
          <hr className="u-line_plane" />
          <div className="t-textColor_sub">授与方法</div>
          <RadioBoxes
            name="diplomaAwardTypeId"
            validator={validator}
            choices={[
              { key: ID_UNCONFIRMED, value: '未確認' },
              ...constants.diplomaAwardTypes,
            ]}
          />
          {(watch('diplomaAwardTypeId') === DIPLOMA_AWARD_TYPE_ID_PER_PERSON ||
            watch('diplomaAwardTypeId') ===
              DIPLOMA_AWARD_TYPE_ID_REPRESENTATIVE) && (
            <>
              <div className="t-textColor_sub">児童合計人数</div>
              <div className="l-flex_center">
                <div className={clsx(styles.numberInput)}>
                  <NullableNumberInput
                    name="diplomaAwardCount"
                    validator={validator}
                  />
                </div>
                名
              </div>
            </>
          )}
          {watch('diplomaAwardTypeId') === DIPLOMA_AWARD_TYPE_ID_OTHER && (
            <>
              <div className="t-textColor_sub">その他詳細</div>
              <TextInput
                name="diplomaAwardOtherDescription"
                validator={validator}
              />
            </>
          )}
        </>
      )}
      <hr className="u-line_plane" />
      <Video validator={validator} constants={constants} />
    </PhotographyAccordion>
  );
};

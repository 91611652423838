import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '../../Shared/Accordion/App';
import { RadioBoxes, TextArea } from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED } from '../../const';
import React, { useEffect } from 'react';
import { Alert } from '@/components/shared/Alert';
import { useFormContext } from 'react-hook-form';
import { TFormInputs } from '@/components/pages/Events/Show/Photography/types';

export const PhotographerExpense: React.FC<{
  validator: TValidator;
}> = ({ validator }) => {
  const { watch, setValue } = useFormContext<TFormInputs>();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasPhotographerExpense' && type === 'change') {
        if (value.hasPhotographerExpense === ID_UNCONFIRMED) {
          setValue('photographerExpenseRemark', '');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);
  return (
    <>
      <PhotographyAccordion title="当日のカメラマンが負担する金額">
        <RadioBoxes
          name="hasPhotographerExpense"
          validator={validator}
          choices={[
            { key: ID_UNCONFIRMED, value: '未確認' },
            { key: 1, value: 'あり' },
            { key: 0, value: 'なし' },
          ]}
        />

        <div className="u-mgb_s" />
        {watch('hasPhotographerExpense') !== ID_UNCONFIRMED && (
          <>
            <div>詳細</div>
            <TextArea
              name="photographerExpenseRemark"
              validator={validator}
              placeholder="当日のカメラマンが負担する金額の詳細を入力してください"
            />
          </>
        )}
        <Alert type="warning" title="負担する金額について">
          <p className="u-mgb_s">
            有料施設で団体負担のためカメラマンの支払う金額が0円の場合は「なし」と登録してください。
          </p>
        </Alert>
      </PhotographyAccordion>
    </>
  );
};

import {
  TConstants,
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { Alert } from '@/components/shared/Alert';
import { ownerUrl } from '@/ts/url';
import { useParams } from 'react-router-dom';
import {
  InnerTable,
  InnerTableBody,
  InnerTableCell,
  InnerTableHead,
  InnerTableRow,
} from '@/components/shared/ResultTable';
import {
  Checkboxes,
  NumberInput,
  RadioBoxes,
  TextArea,
  TextInput,
} from '@/components/shared/Form/Inputs';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import styles from './index.module.scss';
import React, { useEffect } from 'react';
import {
  EVENT_TYPE_ID_GRADUATION_CEREMONY,
  ID_UNCONFIRMED,
  PARTICIPANT_TYPE_ID_CURRENT_PRESCHOOLERS,
  PARTICIPANT_TYPE_ID_EXTERNAL,
  PARTICIPANT_TYPE_ID_EXTERNAL_INSTRUCTOR,
  PARTICIPANT_TYPE_ID_GRADUATION_PRESCHOOLERS,
  PARTICIPANT_TYPE_ID_OTHER,
  PARTICIPANT_TYPE_ID_PARENT,
  PARTICIPANT_TYPE_ID_STAFF,
} from '@/components/pages/Events/Show/Photography/const';
import { EVENT_TYPE_ID_ENTRANCE_CEREMONY } from '../const';

type PhotographySubjectProps = {
  methods: UseFormReturn<TFormInputs>;
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
};

type RouteParams = {
  eventId: string;
};

export const PhotographySubject: React.FC<PhotographySubjectProps> = ({
  methods,
  photography,
  constants,
  validator,
}) => {
  const { eventId } = useParams<RouteParams>();
  return (
    <div className="c-frame">
      <div className="c-section_title">撮影対象</div>
      <hr className="u-line_plane" />
      <PhotographyAccordion title="撮影対象者">
        {photography.eventTypeId !== EVENT_TYPE_ID_ENTRANCE_CEREMONY ? (
          <Checkboxes
            validator={validator}
            choices={constants.photographySubjectTypes}
            name="photographySubjectTypeIds"
          />
        ) : (
          <>
            <Alert type="warning" title="在籍児童や保護者などの参加について">
              新入児童以外の参加については「撮影対象者以外の参加者」に記載をお願いします
            </Alert>
            <div className="t-textColor_sub u-mgt_s">新入児童</div>
            <InnerTable>
              <InnerTableHead>
                <InnerTableRow>
                  {constants.photographySubjectTypes.map((subject) => (
                    <InnerTableCell key={subject.key}>
                      {subject.value}
                    </InnerTableCell>
                  ))}
                </InnerTableRow>
              </InnerTableHead>
              <InnerTableBody>
                <InnerTableRow>
                  {constants.photographySubjectTypes.map((subject, index) => (
                    <InnerTableCell key={subject.key}>
                      <div className={styles.photographySubject}>
                        <div>
                          <NumberInput
                            name={`newChildrenPhotographySubjects.${index}.count`}
                            validator={validator}
                          />
                        </div>
                        <span className="u-mgl_s">名</span>
                      </div>
                    </InnerTableCell>
                  ))}
                </InnerTableRow>
              </InnerTableBody>
            </InnerTable>
          </>
        )}
        <div className="t-textColor_sub u-mgt_s">詳細</div>
        <TextArea
          name="photographySubjectRemark"
          validator={validator}
          placeholder="撮影対象の詳細を入力してください"
        />
        <div className="t-textColor_sub">
          ※その他を選択した場合、分かる場合は学年と人数もそれぞれ記載ください。
        </div>
      </PhotographyAccordion>
      <PhotographyParticipant
        photography={photography}
        constants={constants}
        validator={validator}
      />
      <PhotographyAccordion title="参加人数">
        {photography.participantCount}名
        <div className="t-textColor_sub">
          ※申請サマリの登録情報を表示しています。
        </div>
        <Alert type="error" title="参加人数の変更について">
          <p className="u-mgb_s">
            申請サマリで参加人数の変更を行ってください。
          </p>
          <a
            className="c-btn c-btn_middle_wide c-btn_black"
            href={ownerUrl(`events/requests/${eventId}`)}
            target="_blank"
            rel="noreferrer"
          >
            申請サマリ編集
          </a>
        </Alert>
      </PhotographyAccordion>
      <PhotographyAccordion title="目印">
        <div className="t-textColor_sub">先生</div>
        <TextInput name="subjectMarkForTeacher" validator={validator} />
        <div className="t-textColor_sub">児童</div>
        <TextInput name="subjectMarkForPreschooler" validator={validator} />
        <div className="t-textColor_sub">その他</div>
        <TextInput name="subjectMarkForOther" validator={validator} />
      </PhotographyAccordion>
    </div>
  );
};

const PhotographyParticipant: React.FC<{
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
}> = ({ photography, constants, validator }) => {
  const { watch, setValue } = useFormContext<TFormInputs>();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'participantTypeIds' && type === 'change') {
        if (!value.participantTypeIds?.includes(PARTICIPANT_TYPE_ID_STAFF)) {
          setValue('isStaffParticipantPhotographyPermitted', ID_UNCONFIRMED);
        }
        if (
          !value.participantTypeIds?.includes(
            PARTICIPANT_TYPE_ID_CURRENT_PRESCHOOLERS
          )
        ) {
          setValue(
            'isCurrentPreschoolersParticipantPhotographyPermitted',
            ID_UNCONFIRMED
          );
        }
        if (
          !value.participantTypeIds?.includes(
            PARTICIPANT_TYPE_ID_GRADUATION_PRESCHOOLERS
          )
        ) {
          setValue(
            'isGraduationPreschoolersParticipantPhotographyPermitted',
            ID_UNCONFIRMED
          );
        }
        if (!value.participantTypeIds?.includes(PARTICIPANT_TYPE_ID_PARENT)) {
          setValue('isParentParticipantPhotographyPermitted', ID_UNCONFIRMED);
        }
        if (
          !value.participantTypeIds?.includes(
            PARTICIPANT_TYPE_ID_EXTERNAL_INSTRUCTOR
          )
        ) {
          setValue(
            'isExternalInstructorParticipantPhotographyPermitted',
            ID_UNCONFIRMED
          );
        }
        if (!value.participantTypeIds?.includes(PARTICIPANT_TYPE_ID_EXTERNAL)) {
          setValue('isExternalParticipantPhotographyPermitted', ID_UNCONFIRMED);
        }
        if (!value.participantTypeIds?.includes(PARTICIPANT_TYPE_ID_OTHER)) {
          setValue('isOtherParticipantPhotographyPermitted', ID_UNCONFIRMED);
        } else {
          setValue('otherParticipantRemark', '');
        }
        if (value.participantTypeIds?.length === 0) {
          setValue('participantRemark', '');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);
  return (
    <PhotographyAccordion title="撮影対象者以外の参加者">
      <Checkboxes
        name="participantTypeIds"
        validator={validator}
        choices={constants.photographyParticipantType.filter((typeId) => {
          if (
            (String(typeId.key) === PARTICIPANT_TYPE_ID_CURRENT_PRESCHOOLERS &&
              EVENT_TYPE_ID_ENTRANCE_CEREMONY !== photography.eventTypeId) ||
            (String(typeId.key) ===
              PARTICIPANT_TYPE_ID_GRADUATION_PRESCHOOLERS &&
              EVENT_TYPE_ID_GRADUATION_CEREMONY === photography.eventTypeId)
          ) {
            return false;
          }
          return true;
        })}
      />
      {watch('participantTypeIds')?.includes(PARTICIPANT_TYPE_ID_STAFF) && (
        <>
          <div className="t-textColor_sub">職員写り込み</div>
          <RadioBoxes
            name="isStaffParticipantPhotographyPermitted"
            validator={validator}
            choices={[
              { key: ID_UNCONFIRMED, value: '未確認' },
              { key: 1, value: '可能' },
              { key: 0, value: '不可' },
            ]}
          />
        </>
      )}
      {watch('participantTypeIds')?.includes(
        PARTICIPANT_TYPE_ID_CURRENT_PRESCHOOLERS
      ) && (
        <>
          <div className="t-textColor_sub">在籍児童写り込み</div>
          <RadioBoxes
            name="isCurrentPreschoolersParticipantPhotographyPermitted"
            validator={validator}
            choices={[
              { key: ID_UNCONFIRMED, value: '未確認' },
              { key: 1, value: '可能' },
              { key: 0, value: '不可' },
            ]}
          />
        </>
      )}
      {watch('participantTypeIds')?.includes(
        PARTICIPANT_TYPE_ID_GRADUATION_PRESCHOOLERS
      ) && (
        <>
          <div className="t-textColor_sub">卒園卒業児童写り込み</div>
          <RadioBoxes
            name="isGraduationPreschoolersParticipantPhotographyPermitted"
            validator={validator}
            choices={[
              { key: ID_UNCONFIRMED, value: '未確認' },
              { key: 1, value: '可能' },
              { key: 0, value: '不可' },
            ]}
          />
        </>
      )}
      {watch('participantTypeIds')?.includes(PARTICIPANT_TYPE_ID_PARENT) && (
        <>
          <div className="t-textColor_sub">保護者写り込み</div>
          <RadioBoxes
            name="isParentParticipantPhotographyPermitted"
            validator={validator}
            choices={[
              { key: ID_UNCONFIRMED, value: '未確認' },
              { key: 1, value: '可能' },
              { key: 0, value: '不可' },
            ]}
          />
        </>
      )}
      {watch('participantTypeIds')?.includes(
        PARTICIPANT_TYPE_ID_EXTERNAL_INSTRUCTOR
      ) && (
        <>
          <div className="t-textColor_sub">外部講師写り込み</div>
          <RadioBoxes
            name="isExternalInstructorParticipantPhotographyPermitted"
            validator={validator}
            choices={[
              { key: ID_UNCONFIRMED, value: '未確認' },
              { key: 1, value: '可能' },
              { key: 0, value: '不可' },
            ]}
          />
        </>
      )}
      {watch('participantTypeIds')?.includes(PARTICIPANT_TYPE_ID_EXTERNAL) && (
        <>
          <div className="t-textColor_sub">地域・外部の方写り込み</div>
          <RadioBoxes
            name="isExternalParticipantPhotographyPermitted"
            validator={validator}
            choices={[
              { key: ID_UNCONFIRMED, value: '未確認' },
              { key: 1, value: '可能' },
              { key: 0, value: '不可' },
            ]}
          />
        </>
      )}
      {watch('participantTypeIds')?.includes(PARTICIPANT_TYPE_ID_OTHER) && (
        <>
          <div className="t-textColor_sub">その他の方写り込み</div>
          <RadioBoxes
            name="isOtherParticipantPhotographyPermitted"
            validator={validator}
            choices={[
              { key: ID_UNCONFIRMED, value: '未確認' },
              { key: 1, value: '可能' },
              { key: 0, value: '不可' },
            ]}
          />
        </>
      )}
      {watch('participantTypeIds')?.includes(PARTICIPANT_TYPE_ID_OTHER) && (
        <>
          <div className="t-textColor_sub">その他詳細</div>
          <TextArea
            name="otherParticipantRemark"
            placeholder="その他詳細を入力してください"
            rows={3}
            validator={validator}
          />
        </>
      )}
      {watch('participantTypeIds')?.length > 0 && (
        <>
          <div className="t-textColor_sub">詳細</div>
          <TextArea
            name="participantRemark"
            placeholder="詳細を入力してください"
            rows={3}
            validator={validator}
          />
        </>
      )}
    </PhotographyAccordion>
  );
};

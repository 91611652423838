import {
  TConstants,
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { UseFormReturn } from 'react-hook-form';
import { TValidator } from '@/components/shared/Form/types';
import { TimeSchedule } from '@/components/pages/Events/Show/Photography/PhotographyEvent/TimeSchedule';
import { EmergencyContact } from '@/components/pages/Events/Show/Photography/PhotographyEvent/EmergencyContact';
import { TeacherInChargeOfPhotography } from '@/components/pages/Events/Show/Photography/PhotographyEvent/TeacherInChargeOfPhotography';
import { Christmas } from './Christmas';
import { SenNoteForPhotography } from '@/components/pages/Events/Show/Photography/PhotographyEvent/SenNoteForPhotography';
import { SummerFestival } from './SummerFestival';
import { DailyChildcare } from './DailyChildcare';
import { MochiPound } from './MochiPound';
import { Halloween } from './Halloween';
import { Pool } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Pool';
import {
  EVENT_TYPE_ID_CHRISTMAS,
  EVENT_TYPE_ID_ENTRANCE_CEREMONY,
  EVENT_TYPE_ID_GRADUATION_CEREMONY,
  EVENT_TYPE_ID_HALLOWEEN,
  EVENT_TYPE_ID_MOCHI_POUND,
  EVENT_TYPE_ID_SPORTS_DAY,
  EVENT_TYPE_ID_SUMMER_FESTIVAL,
  EVENT_TYPE_ID_DAILY_CHILDCARE,
  EVENT_TYPE_ID_THROWING_BEANS,
  EVENT_TYPE_ID_SLEEPOVER,
  EVENT_TYPE_ID_SPORTS_DAY_REHEARSAL,
  EVENT_TYPE_ID_RECITAL,
  EVENT_TYPE_ID_RECITAL_REHEARSAL,
  EVENT_TYPE_ID_OTHER,
  EVENT_TYPE_ID_POOL,
} from '../const';
import { EntranceCeremony } from './EntranceCeremony';
import { ThrowingBeans } from '@/components/pages/Events/Show/Photography/PhotographyEvent/ThrowingBeans';
import { Sleepover } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Sleepover';
import { PhotographerExpense } from '@/components/pages/Events/Show/Photography/PhotographyEvent/PhotographerExpense';
import { EventType } from '@/components/pages/Events/Show/Photography/PhotographyEvent/EventType';
import { GraduationCeremony } from './GraduationCeremony';
import { SportsDay } from './SportsDay';
import { Recital } from './Recital';
import { OtherPhotography } from '@/components/pages/Events/Show/Photography/PhotographyEvent/OtherPhotography';
import { shouldRenderPhotographerExpense } from '@/components/pages/Events/Show/Photography/Shared/shouldRenderPhotographyContent';
import { PARENT_LIST_ITEMS } from '../listItem';

type EventProps = {
  methods: UseFormReturn<TFormInputs>;
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
};

export const PhotographyEvent: React.FC<EventProps> = ({
  methods,
  photography,
  constants,
  validator,
}) => {
  return (
    <div className="c-frame">
      <div className="c-section_title" id={PARENT_LIST_ITEMS.event.id}>
        {PARENT_LIST_ITEMS.event.name}
      </div>
      <hr className="u-line_plane" />
      <EventType photography={photography} />
      {photography.eventTypeId === EVENT_TYPE_ID_CHRISTMAS && (
        <Christmas
          photography={photography}
          validator={validator}
          constants={constants}
        />
      )}
      {photography.eventTypeId === EVENT_TYPE_ID_SUMMER_FESTIVAL && (
        <SummerFestival
          photography={photography}
          validator={validator}
          constants={constants}
        />
      )}
      {photography.eventTypeId === EVENT_TYPE_ID_DAILY_CHILDCARE && (
        <DailyChildcare
          photography={photography}
          validator={validator}
          constants={constants}
        />
      )}
      {photography.eventTypeId === EVENT_TYPE_ID_MOCHI_POUND && (
        <MochiPound
          photography={photography}
          validator={validator}
          constants={constants}
        />
      )}
      {photography.eventTypeId === EVENT_TYPE_ID_HALLOWEEN && (
        <Halloween photography={photography} validator={validator} />
      )}
      {photography.eventTypeId === EVENT_TYPE_ID_POOL && (
        <Pool
          photography={photography}
          methods={methods}
          constants={constants}
          validator={validator}
        />
      )}
      {photography.eventTypeId === EVENT_TYPE_ID_ENTRANCE_CEREMONY && (
        <EntranceCeremony
          photography={photography}
          constants={constants}
          validator={validator}
        />
      )}
      {photography.eventTypeId === EVENT_TYPE_ID_THROWING_BEANS && (
        <ThrowingBeans
          photography={photography}
          methods={methods}
          validator={validator}
        />
      )}
      {photography.eventTypeId === EVENT_TYPE_ID_SLEEPOVER && (
        <Sleepover
          photography={photography}
          validator={validator}
          constants={constants}
          methods={methods}
        />
      )}
      {(photography.eventTypeId === EVENT_TYPE_ID_SPORTS_DAY ||
        photography.eventTypeId === EVENT_TYPE_ID_SPORTS_DAY_REHEARSAL) && (
        <SportsDay
          validator={validator}
          photography={photography}
          constants={constants}
        />
      )}
      {(photography.eventTypeId === EVENT_TYPE_ID_RECITAL ||
        photography.eventTypeId === EVENT_TYPE_ID_RECITAL_REHEARSAL) && (
        <Recital
          validator={validator}
          constants={constants}
          photography={photography}
        />
      )}
      {photography.eventTypeId === EVENT_TYPE_ID_GRADUATION_CEREMONY && (
        <GraduationCeremony
          photography={photography}
          constants={constants}
          validator={validator}
        />
      )}
      {photography.eventTypeId === EVENT_TYPE_ID_OTHER && (
        <OtherPhotography
          photography={photography}
          validator={validator}
          constants={constants}
        />
      )}
      <TimeSchedule photography={photography} validator={validator} />
      <EmergencyContact methods={methods} validator={validator} />
      <TeacherInChargeOfPhotography methods={methods} validator={validator} />
      {shouldRenderPhotographerExpense(photography) && (
        <PhotographerExpense validator={validator} />
      )}
      <SenNoteForPhotography validator={validator} />
    </div>
  );
};

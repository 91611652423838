import {
  isValidationError,
  putJson,
  renderError,
  toMessages,
} from '@/ts/useApi';
import { TMutate, useJsonApi } from '@/ts/useJsonApi';
import { Prompt, useParams } from 'react-router-dom';
import Loading from '@/components/shared/Loading/SvgLoading';
import { AttachedDocument } from './AttachedDocument';
import {
  TFormInputs,
  TPhotographyResponse,
  TPutData,
  TSleepoverMeal,
} from './types';
import { SubmitHandler } from 'react-hook-form';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { errorToast, successToast } from '@/ts/toast';
import React, { useCallback, useEffect, useState } from 'react';
import { TValidator } from '@/components/shared/Form/types';
import { SaveForm } from '@/components/pages/Events/Show/Photography/SaveForm';
import { alertApiError } from '@/ts/formValidation';
import {
  BREAK_FIRST_MEAL_TYPE_ID,
  DINNER_MEAL_TYPE_ID,
  EVENT_TYPE_ID_ENTRANCE_CEREMONY,
  EVENT_TYPE_ID_GRADUATION_CEREMONY,
  ID_NONE,
  ID_UNCONFIRMED,
  LUNCH_MEAL_TYPE_ID,
  OTHER_EVENT_TYPE_ID_DEFAULT,
  PARTICIPANT_TYPE_ID_CURRENT_PRESCHOOLERS,
  PARTICIPANT_TYPE_ID_GRADUATION_PRESCHOOLERS,
  PARTICIPANT_TYPE_ID_STAFF,
  RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_CANCEL,
  RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_HAS_CHANGE,
  RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_NO_CHANGE,
  RAINY_DAY_ACTION_ID_NO_CANCEL,
  RAINY_DAY_ACTION_ID_POSTPONE,
  SPECIFIED_DIPLOMA_AWARD_PHOTOGRAPHY_POSITION_TYPE_ID_NO_POSITION,
  TRANSPORTATION_TYPE_BICYCLE,
  TRANSPORTATION_TYPE_CAR,
  TRANSPORTATION_TYPE_MOTORCYCLE,
} from './const';
import copy from 'copy-to-clipboard';
import { getPhotographyMailText } from './Shared/getPhotographyMailText';

export const Photography: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const {
    data: response,
    error,
    mutate,
  } = useJsonApi<TPhotographyResponse>(
    '/api/events/' + eventId + '/photographies'
  );

  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <Loading />;
  }
  return (
    <PhotographyBody response={response} mutate={mutate} eventId={eventId} />
  );
};

const PhotographyBody: React.FC<{
  response: TPhotographyResponse;
  mutate: TMutate<TPhotographyResponse>;
  eventId: string;
}> = ({ response, mutate, eventId }) => {
  const [validator, setValidator] = useState<TValidator>(response.validator);
  const getSleepoverMeals = (
    sleepoverMeals: TSleepoverMeal[] | undefined
  ): TSleepoverMeal[] => {
    const mealTypes = [
      { day: 1, mealTypeId: LUNCH_MEAL_TYPE_ID },
      { day: 1, mealTypeId: DINNER_MEAL_TYPE_ID },
      { day: 2, mealTypeId: BREAK_FIRST_MEAL_TYPE_ID },
      { day: 2, mealTypeId: LUNCH_MEAL_TYPE_ID },
    ];

    return mealTypes.map(({ day, mealTypeId }) => {
      const meal = sleepoverMeals?.find(
        (m) => m.day === day && m.mealTypeId === mealTypeId
      );
      let preparingMealTypeId = meal?.preparingMealTypeId;
      if (preparingMealTypeId === undefined) {
        preparingMealTypeId = ID_UNCONFIRMED;
      }
      if (meal?.hasPreparingMeal === false) {
        preparingMealTypeId = ID_NONE;
      }
      return {
        day: day,
        preparingMealTypeId: preparingMealTypeId,
        mealTypeId: mealTypeId,
      };
    });
  };
  const [changed, setChanged] = useState(false);
  useEffect(() => {
    const handler = (e: Event) => {
      if (changed) {
        e.preventDefault();
      }
    };
    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, [changed]);

  const handleFormChange = useCallback(() => {
    setChanged(true);
  }, []);

  const onSubmit: SubmitHandler<TFormInputs> = async (
    formData: TFormInputs
  ) => {
    if (!handleFirstSubmit()) {
      return;
    }
    try {
      await storePhotography(formData);
      setChanged(false);
      setValidator({ rules: {} });
      successToast('保存しました');
      mutate(undefined, { keepCurrentData: true });
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  const copyToClipboard: SubmitHandler<TFormInputs> = async (
    formData: TFormInputs
  ) => {
    if (process.env.REACT_APP_FEATURE_PHOTOGRAPHY_CLIPBOARD !== 'true') {
      return;
    }
    if (response === undefined) {
      errorToast('コピーに失敗しました');
      return;
    }
    if (!handleFirstSubmit()) {
      return;
    }
    try {
      await storePhotography(formData);
      setChanged(false);
      setValidator({ rules: {} });
      mutate(undefined, { keepCurrentData: true });
      if (window.navigator?.userActivation?.isActive === false) {
        // この場合、クリップボードへのコピーは必ず失敗するので、メッセージを出してもう一回コピーボタンを押してもらう
        errorToast(
          '撮影情報の保存は成功しましたが、コピーは失敗しました。再度コピーボタンを押してください'
        );
      } else {
        copy(
          getPhotographyMailText(
            formData,
            response.data.photography,
            response.data.constants
          ),
          { message: '#{key}を押してクリップボードにコピーしてください' }
        )
          ? successToast('撮影情報を保存し、クリップボードにコピーしました')
          : errorToast('コピーに失敗しました');
      }
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  const handleErrorResponse = (e: unknown) => {
    if (isValidationError(e)) {
      setValidator(e.jsonMessage.validator);
      alertApiError();
    } else {
      errorToast(`エラーが発生しました: ${toMessages(e)}`);
    }
  };

  const handleFirstSubmit = () => {
    if (!response?.data.photography.isPhotographyAlreadyRegistered) {
      if (
        !window.confirm(
          'この内容で保存してよろしいですか？\n保存すると責任者画面で詳細登録ができなくなります。'
        )
      ) {
        return false;
      }
    }
    return true;
  };

  const storePhotography: SubmitHandler<TFormInputs> = usePreventDuplicateCall(
    async (formData: TFormInputs) => {
      const toBoolean = (value: number) =>
        value === ID_UNCONFIRMED ? null : !!value;
      const toNumber = (value: number) =>
        value === ID_UNCONFIRMED ? null : value;

      const toNumberArray = (array: string[]) =>
        array.map((value) => parseInt(value));
      const putData: TPutData = Object.assign({
        manualMeetingTime: formData.manualMeetingTime,
        meetingTimeChangeReason: formData.meetingTimeChangeReason,
        meetingPlaceAccess: formData.meetingPlaceAccess,
        meetingPlaceTransportationTypeIds: toNumberArray(
          formData.meetingPlaceTransportationTypeIds
        ),
        hasCarParkingLotAtMeetingPlace:
          formData.meetingPlaceTransportationTypeIds?.includes(
            TRANSPORTATION_TYPE_CAR
          ) ||
          formData.meetingPlaceTransportationTypeIds?.includes(
            TRANSPORTATION_TYPE_MOTORCYCLE
          )
            ? formData.hasCarParkingLotAtMeetingPlace === 1
            : null,
        meetingPlaceCarParkingLotRemark:
          formData.meetingPlaceCarParkingLotRemark,
        hasBicycleParkingLotAtMeetingPlace:
          formData.meetingPlaceTransportationTypeIds?.includes(
            TRANSPORTATION_TYPE_BICYCLE
          ) ||
          formData.meetingPlaceTransportationTypeIds?.includes(
            TRANSPORTATION_TYPE_MOTORCYCLE
          )
            ? formData.hasBicycleParkingLotAtMeetingPlace === 1
            : null,
        meetingPlaceBicycleParkingLotRemark:
          formData.meetingPlaceBicycleParkingLotRemark,
        photographingPlaces: formData.photographingPlaces,
        photographySubjectTypeIds: formData.photographySubjectTypeIds,
        newChildrenPhotographySubjects:
          formData.newChildrenPhotographySubjects.map((subject) => ({
            ...subject,
            count:
              subject.count === null || isNaN(subject.count)
                ? 0
                : subject.count,
          })),
        photographySubjectRemark: formData.photographySubjectRemark,
        participantTypeIds: toNumberArray(
          formData.participantTypeIds.filter((typeId) => {
            if (
              (typeId === PARTICIPANT_TYPE_ID_CURRENT_PRESCHOOLERS &&
                EVENT_TYPE_ID_ENTRANCE_CEREMONY !==
                  response.data.photography.eventTypeId) ||
              (typeId === PARTICIPANT_TYPE_ID_GRADUATION_PRESCHOOLERS &&
                EVENT_TYPE_ID_GRADUATION_CEREMONY ===
                  response.data.photography.eventTypeId)
            ) {
              return false;
            }
            return true;
          })
        ),
        isStaffParticipantPhotographyPermitted: toBoolean(
          formData.isStaffParticipantPhotographyPermitted
        ),
        isCurrentPreschoolersParticipantPhotographyPermitted: toBoolean(
          formData.isCurrentPreschoolersParticipantPhotographyPermitted
        ),
        isGraduationPreschoolersParticipantPhotographyPermitted: toBoolean(
          formData.isGraduationPreschoolersParticipantPhotographyPermitted
        ),
        isParentParticipantPhotographyPermitted: toBoolean(
          formData.isParentParticipantPhotographyPermitted
        ),
        isExternalInstructorParticipantPhotographyPermitted: toBoolean(
          formData.isExternalInstructorParticipantPhotographyPermitted
        ),
        isExternalParticipantPhotographyPermitted: toBoolean(
          formData.isExternalParticipantPhotographyPermitted
        ),
        isOtherParticipantPhotographyPermitted: toBoolean(
          formData.isOtherParticipantPhotographyPermitted
        ),
        otherParticipantRemark: formData.otherParticipantRemark ?? '',
        participantRemark: formData.participantRemark ?? '',
        subjectMarkForTeacher: formData.subjectMarkForTeacher,
        subjectMarkForPreschooler: formData.subjectMarkForPreschooler,
        subjectMarkForOther: formData.subjectMarkForOther,
        timeSchedule: formData.timeSchedule,
        emergencyContacts: formData.emergencyContacts,
        teachersInChargeOfPhotography: formData.teachersInChargeOfPhotography,
        senNote: formData.senNote,
        hasGift: toBoolean(formData.hasGift),
        giftStyleTypeId: toNumber(formData.giftStyleTypeId),
        giftStyleOtherDescription: formData.giftStyleOtherDescription,
        hasSantaArrival: toBoolean(formData.hasSantaArrival),
        isCryFacePermittedForSantaArrival: toBoolean(
          formData.isCryFacePermittedForSantaArrival
        ),
        poolClothesTypeId: toNumber(formData.poolClothesTypeId),
        poolClothesOtherDescription: formData.poolClothesOtherDescription,
        canPrompt: toBoolean(formData.canPrompt),
        promptRemark: formData.promptRemark || null,
        hasArrivalAndDeparturePhoto: toBoolean(
          formData.hasArrivalAndDeparturePhoto
        ),
        arrivalAndDeparturePhotoRemark:
          formData.arrivalAndDeparturePhotoRemark || null,
        hasLunchPhoto: toBoolean(formData.hasLunchPhoto),
        lunchPhotoRemark: formData.lunchPhotoRemark || null,
        groupPhotoSenNote: formData.groupPhotoSenNote,
        hasTransportation: toBoolean(formData.hasTransportation),
        isPhotographerTransportationOnboardPermitted: toBoolean(
          formData.isPhotographerTransportationOnboardPermitted
        ),
        transportationSenNote: formData.transportationSenNote || null,
        hasSpecifiedPosition: toBoolean(formData.hasSpecifiedPosition),
        specifiedPositionSenNote: formData.specifiedPositionSenNote || null,
        hasNgPhotographySubject: toBoolean(formData.hasNgPhotographySubject),
        ngPhotographySubjectTypeIds: toNumberArray(
          formData.ngPhotographySubjectTypeIds
        ),
        ngPhotographySubjectSenNote:
          formData.ngPhotographySubjectSenNote || null,
        hasRest: toBoolean(formData.hasRest),
        restTimeStartAt: formData.restTimeStartAt || null,
        restTimeEndAt: formData.restTimeEndAt || null,
        restPlaceTypeId: toNumber(formData.restPlaceTypeId),
        restPlaceOtherDescription: formData.restPlaceOtherDescription || null,
        restSenNote: formData.restSenNote || null,
        belongingsTypeIds: toNumberArray(formData.belongingsTypeIds),
        belongingsSenNote: formData.belongingsSenNote || null,
        societyRequest: formData.societyRequest || null,
        summerFestivalVenue: formData.summerFestivalVenue,
        hasStalls: toBoolean(formData.hasStalls),
        stallsCount: formData.stallsCount || null,
        hasBonDance: toBoolean(formData.hasBonDance),
        bonDanceStyleTypeId: toNumber(formData.bonDanceStyleTypeId),
        bonDanceStyleOtherDescription: formData.bonDanceStyleOtherDescription,
        bonDanceSongCount: formData.bonDanceSongCount,
        bonDanceDurationMinutes: formData.bonDanceDurationMinutes,
        bonDanceCircleStyleTypeId: toNumber(formData.bonDanceCircleStyleTypeId),
        isBonDancePhotographyPermittedInCircle: toBoolean(
          formData.isBonDancePhotographyPermittedInCircle
        ),
        hasNapPhoto: toBoolean(formData.hasNapPhoto),
        hasPortableShrine: toBoolean(formData.hasPortableShrine),
        portableShrineRouteTypeId: toNumber(formData.portableShrineRouteTypeId),
        photographyEnvironmentTypeId: toNumber(
          formData.photographyEnvironmentTypeId
        ),
        riceMortarCount: formData.riceMortarCount,
        poundStyle: formData.poundStyle,
        hasMedalAward: toBoolean(formData.hasMedalAward),
        medalAwardStyleTypeId: toNumber(formData.medalAwardStyleTypeId),
        medalAwardOtherDescription: formData.medalAwardOtherDescription || null,
        hasOgreArrival: toBoolean(formData.hasOgreArrival),
        isCryFacePermittedForOgreArrival: toBoolean(
          formData.isCryFacePermittedForOgreArrival
        ),
        hasDiplomaAward: toBoolean(formData.hasDiplomaAward),
        ...(formData.specifiedDiplomaAwardPhotographyPositionTypeId ===
        ID_UNCONFIRMED
          ? {
              hasSpecifiedDiplomaAwardPhotographyPosition: null,
            }
          : formData.specifiedDiplomaAwardPhotographyPositionTypeId ===
            SPECIFIED_DIPLOMA_AWARD_PHOTOGRAPHY_POSITION_TYPE_ID_NO_POSITION
          ? {
              hasSpecifiedDiplomaAwardPhotographyPosition: false,
            }
          : {
              hasSpecifiedDiplomaAwardPhotographyPosition: true,
              specifiedDiplomaAwardPhotographyPositionTypeId:
                formData.specifiedDiplomaAwardPhotographyPositionTypeId,
            }),
        specifiedDiplomaAwardPhotographyPositionOtherDescription:
          formData.specifiedDiplomaAwardPhotographyPositionOtherDescription ||
          null,
        diplomaAwardTypeId: toNumber(formData.diplomaAwardTypeId),
        diplomaAwardCount: formData.diplomaAwardCount || null,
        diplomaAwardOtherDescription:
          formData.diplomaAwardOtherDescription || null,
        isPhotographyInsideTrackPermitted: toBoolean(
          formData.isPhotographyInsideTrackPermitted
        ),
        hasSkippingPhotography: toBoolean(formData.hasSkippingPhotography),
        skippingPhotographyRemark: formData.skippingPhotographyRemark || null,
        openAt: formData.openAt || null,
        openingCeremonyStartAt: formData.openingCeremonyStartAt || null,
        hasVenueEnvironment: toBoolean(formData.hasVenueEnvironment),
        hasParentSeats: toBoolean(formData.hasParentSeats),
        parentSeatTypeIds: toNumberArray(formData.parentSeatTypeIds),
        parentSeatOtherDescription: formData.parentSeatOtherDescription || null,
        lightingTypeId: toNumber(formData.lightingTypeId),
        lightingOtherDescription: formData.lightingOtherDescription || null,
        venueSize: formData.venueSize || null,
        hasContentChangeWhenRainy:
          formData.rainyDayActionForPhotographerId ===
          RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_HAS_CHANGE
            ? true
            : formData.rainyDayActionForPhotographerId ===
              RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_NO_CHANGE
            ? false
            : null,
        rainyChangePoint: formData.rainyChangePoint || null,
        rainyPhotographingPlaces: formData.rainyPhotographingPlaces || [],
        hasMeetingPlaceChangeWhenRainy: toBoolean(
          formData.hasMeetingPlaceChangeWhenRainy
        ),
        cancellableDayTypeId: toNumber(formData.cancellableDayTypeId),
        cancelDecisionTime: formData.cancelDecisionTime || null,
        cancelNotificationTypeId: toNumber(formData.cancelNotificationTypeId),
        cancelNotificationOtherDescription:
          formData.cancelNotificationOtherDescription,
        photographingCautionRemark: formData.photographingCautionRemark || null,
        isPhotographerCostumeAvailable: toBoolean(
          formData.isPhotographerCostumeAvailable
        ),
        hasFashionShow: toBoolean(formData.hasFashionShow),
        fashionShowWalkStyle: formData.fashionShowWalkStyle || null,
        hasVideo: toBoolean(formData.hasVideo),
        videographerTypeId: toNumber(formData.videographerTypeId),
        videoRemark: formData.videoRemark || null,
        hasSignboardPhoto: toBoolean(formData.hasSignboardPhoto),
        signboardPhotoTimingTypeIds: toNumberArray(
          formData.signboardPhotoTimingTypeIds
        ),
        signboardPhotoTargetMessageTypeId: toBoolean(formData.hasSignboardPhoto)
          ? toNumber(formData.signboardPhotoTargetMessageTypeId)
          : null,
        signboardPhotoRemark: formData.signboardPhotoRemark || null,
        photographingDayCount: toNumber(formData.photographingDayCount),
        hasSleepingFacePhoto: toBoolean(formData.hasSleepingFacePhoto),
        canSleepover: toBoolean(formData.canSleepover),
        sleepoverPlace: formData.sleepoverPlace || null,
        photographerHotelPlaces: formData.photographerHotelPlaces || [],
        sleepoverMeals:
          formData.sleepoverMeals.map((meal) => ({
            day: toNumber(meal.day),
            preparingMealTypeId:
              meal.preparingMealTypeId !== ID_NONE &&
              meal.preparingMealTypeId !== null &&
              meal.preparingMealTypeId !== undefined
                ? toNumber(meal.preparingMealTypeId)
                : null,
            mealTypeId: toNumber(meal.mealTypeId),
            hasPreparingMeal:
              meal.preparingMealTypeId !== undefined &&
              meal.preparingMealTypeId !== null &&
              meal.preparingMealTypeId !== ID_UNCONFIRMED
                ? meal.preparingMealTypeId !== ID_NONE
                : null,
          })) || [],
        sleepoverSenNote: formData.sleepoverSenNote || null,
        hasPhotographerExpense: toBoolean(formData.hasPhotographerExpense),
        photographerExpenseRemark: formData.photographerExpenseRemark || null,
        isTripodPermitted: toBoolean(formData.isTripodPermitted),
        requireOwnTripod: toBoolean(formData.requireOwnTripod),
        tripodSenNote: formData.tripodSenNote || null,
        isRentalStepladderAvailable: toBoolean(
          formData.isRentalStepladderAvailable
        ),
        albumOptionSenNote: formData.albumOptionSenNote || null,
        equipmentTypeIds: formData.equipmentTypeIds,
        equipmentSenNote: formData.equipmentSenNote || null,
        tieredPlatformSenNote: formData.tieredPlatformSenNote || null,
        isStrobePermitted: toBoolean(formData.isStrobePermitted),
        strobeSenNote: formData.strobeSenNote || null,
        otherPhotographyTypeId: toNumber(formData.otherPhotographyTypeId),
        hasDressCode: toBoolean(formData.hasDressCode),
        diggingMethodTypeId: toNumber(formData.diggingMethodTypeId),
        diggingMethodOtherDescription:
          formData.diggingMethodOtherDescription || null,
        hasBirthdayChildrenPhoto: toBoolean(formData.hasBirthdayChildrenPhoto),
        birthdayChildrenCount: formData.birthdayChildrenCount || null,
        buyersSellersRemark: formData.buyersSellersRemark || null,
        shopCount: formData.shopCount || null,
        shopPhotographyVenue: formData.shopPhotographyVenue || null,
      });
      await putJson(`/api/events/${eventId}/photographies`, putData);
    }
  );

  const fromBoolean = (
    value: boolean | null | undefined,
    defaultValue: number = ID_UNCONFIRMED
  ) => (value === null || value === undefined ? defaultValue : value ? 1 : 0);
  const fromNumberArray = (array: number[] | undefined) =>
    array === undefined ? [] : array.map((value) => value.toString());
  const defaultValues: TFormInputs = {
    manualMeetingTime: response.data.photography.manualMeetingTime,
    meetingTimeChangeReason: response.data.photography.meetingTimeChangeReason,
    meetingPlaceAccess: response.data.photography.meetingPlaceAccess || '',
    meetingPlaceTransportationTypeIds: fromNumberArray(
      response.data.photography.meetingPlaceTransportationTypeIds
    ),
    hasCarParkingLotAtMeetingPlace: response.data.photography
      .hasCarParkingLotAtMeetingPlace
      ? 1
      : 0,
    meetingPlaceCarParkingLotRemark:
      response.data.photography.meetingPlaceCarParkingLotRemark || '',
    hasBicycleParkingLotAtMeetingPlace: response.data.photography
      .hasBicycleParkingLotAtMeetingPlace
      ? 1
      : 0,
    meetingPlaceBicycleParkingLotRemark:
      response.data.photography.meetingPlaceBicycleParkingLotRemark || '',
    photographingPlace: response.data.photography.photographingPlace || '',
    photographingPlaceMemo:
      response.data.photography.photographingPlaceMemo || '',
    photographingPlaces:
      response.data.photography.photographingPlaces.length > 0
        ? response.data.photography.photographingPlaces
        : [{ name: '', address: '' }],
    photographySubjectTypeIds: fromNumberArray(
      response.data.photography.photographySubjectTypeIds
    ),
    newChildrenPhotographySubjects:
      response.data.constants.photographySubjectTypes.map((subject) => ({
        id: Number(subject.key),
        count:
          response.data.photography.newChildrenPhotographySubjects?.find(
            (s) => s.id === Number(subject.key)
          )?.count || null,
      })),
    photographySubjectRemark: response.data.photography.photographySubjectRemark
      ? response.data.photography.photographySubjectRemark
      : '',
    participantTypeIds: response?.data.photography
      .isPhotographyAlreadyRegistered
      ? fromNumberArray(
          response.data.photography.participantTypeIds?.filter((typeId) => {
            if (
              (String(typeId) === PARTICIPANT_TYPE_ID_CURRENT_PRESCHOOLERS &&
                EVENT_TYPE_ID_ENTRANCE_CEREMONY !==
                  response.data.photography.eventTypeId) ||
              (String(typeId) === PARTICIPANT_TYPE_ID_GRADUATION_PRESCHOOLERS &&
                EVENT_TYPE_ID_GRADUATION_CEREMONY ===
                  response.data.photography.eventTypeId)
            ) {
              return false;
            }
            return true;
          })
        )
      : [PARTICIPANT_TYPE_ID_STAFF],
    isStaffParticipantPhotographyPermitted: fromBoolean(
      response.data.photography.isStaffParticipantPhotographyPermitted
    ),
    isCurrentPreschoolersParticipantPhotographyPermitted: fromBoolean(
      response.data.photography
        .isCurrentPreschoolersParticipantPhotographyPermitted
    ),
    isGraduationPreschoolersParticipantPhotographyPermitted: fromBoolean(
      response.data.photography
        .isGraduationPreschoolersParticipantPhotographyPermitted
    ),
    isParentParticipantPhotographyPermitted: fromBoolean(
      response.data.photography.isParentParticipantPhotographyPermitted
    ),
    isExternalInstructorParticipantPhotographyPermitted: fromBoolean(
      response.data.photography
        .isExternalInstructorParticipantPhotographyPermitted
    ),
    isExternalParticipantPhotographyPermitted: fromBoolean(
      response.data.photography.isExternalParticipantPhotographyPermitted
    ),
    isOtherParticipantPhotographyPermitted: fromBoolean(
      response.data.photography.isOtherParticipantPhotographyPermitted
    ),
    otherParticipantRemark:
      response.data.photography.otherParticipantRemark ?? '',
    participantRemark: response.data.photography.participantRemark ?? '',
    subjectMarkForTeacher: response.data.photography.subjectMarkForTeacher
      ? response.data.photography.subjectMarkForTeacher
      : '',
    subjectMarkForPreschooler: response.data.photography
      .subjectMarkForPreschooler
      ? response.data.photography.subjectMarkForPreschooler
      : '',
    subjectMarkForOther: response.data.photography.subjectMarkForOther
      ? response.data.photography.subjectMarkForOther
      : '',
    timeSchedule: response.data.photography.timeSchedule || '',
    emergencyContacts:
      response.data.photography.emergencyContacts.length > 0
        ? response.data.photography.emergencyContacts
        : [
            {
              telephoneNumber: '',
              name: '',
            },
          ],
    teachersInChargeOfPhotography:
      response.data.photography.teachersInChargeOfPhotography.length > 0
        ? response.data.photography.teachersInChargeOfPhotography
        : [''],
    senNote: response.data.photography.senNote || '',
    hasGift: fromBoolean(response.data.photography.hasGift),
    giftStyleTypeId:
      response.data.photography.giftStyleTypeId || ID_UNCONFIRMED,
    giftStyleOtherDescription: response.data.photography
      .giftStyleOtherDescription
      ? response.data.photography.giftStyleOtherDescription
      : '',
    hasSantaArrival: fromBoolean(response.data.photography.hasSantaArrival),
    isCryFacePermittedForSantaArrival: fromBoolean(
      response.data.photography.isCryFacePermittedForSantaArrival
    ),
    poolClothesTypeId:
      response.data.photography.poolClothesTypeId || ID_UNCONFIRMED,
    poolClothesOtherDescription: response.data.photography
      .poolClothesOtherDescription
      ? response.data.photography.poolClothesOtherDescription
      : '',
    canPrompt: fromBoolean(response.data.photography.canPrompt),
    promptRemark: response.data.photography.promptRemark || '',
    hasArrivalAndDeparturePhoto: fromBoolean(
      response.data.photography.hasArrivalAndDeparturePhoto
    ),
    arrivalAndDeparturePhotoRemark:
      response.data.photography.arrivalAndDeparturePhotoRemark || '',
    hasLunchPhoto: fromBoolean(response.data.photography.hasLunchPhoto),
    lunchPhotoRemark: response.data.photography.lunchPhotoRemark || '',
    groupPhotoSenNote: response.data.photography.groupPhotoSenNote || '',
    hasTransportation: fromBoolean(response.data.photography.hasTransportation),
    isPhotographerTransportationOnboardPermitted: fromBoolean(
      response.data.photography.isPhotographerTransportationOnboardPermitted
    ),
    transportationSenNote:
      response.data.photography.transportationSenNote || '',
    hasSpecifiedPosition: fromBoolean(
      response.data.photography.hasSpecifiedPosition
    ),
    specifiedPositionSenNote:
      response.data.photography.specifiedPositionSenNote || '',
    hasNgPhotographySubject: fromBoolean(
      response.data.photography.hasNgPhotographySubject
    ),
    ngPhotographySubjectTypeIds: fromNumberArray(
      response.data.photography.ngPhotographySubjectTypeIds
    ),
    ngPhotographySubjectSenNote:
      response.data.photography.ngPhotographySubjectSenNote || '',
    hasRest: fromBoolean(response.data.photography.hasRest),
    isRestTimeToBeDetermined:
      response.data.photography.restTimeStartAt === null ||
      response.data.photography.restTimeStartAt === undefined,
    restTimeStartAt: response.data.photography.restTimeStartAt || '',
    restTimeEndAt: response.data.photography.restTimeEndAt || '',
    restPlaceTypeId:
      response.data.photography.restPlaceTypeId || ID_UNCONFIRMED,
    restPlaceOtherDescription:
      response.data.photography.restPlaceOtherDescription || '',
    restSenNote: response.data.photography.restSenNote || '',
    belongingsTypeIds: fromNumberArray(
      response.data.photography.belongingsTypeIds
    ),
    belongingsSenNote: response.data.photography.belongingsSenNote || '',
    societyRequest: response.data.photography.societyRequest || '',
    summerFestivalVenue: response.data.photography.summerFestivalVenue || '',
    hasStalls: fromBoolean(response.data.photography.hasStalls),
    stallsCount: response.data.photography.stallsCount || null,
    hasBonDance: fromBoolean(response.data.photography.hasBonDance),
    bonDanceStyleTypeId:
      response.data.photography.bonDanceStyleTypeId || ID_UNCONFIRMED,
    bonDanceStyleOtherDescription:
      response.data.photography.bonDanceStyleOtherDescription || '',
    bonDanceCircleStyleTypeId:
      response.data.photography.bonDanceCircleStyleTypeId || ID_UNCONFIRMED,
    bonDanceSongCount: response.data.photography.bonDanceSongCount || null,
    bonDanceDurationMinutes:
      response.data.photography.bonDanceDurationMinutes || null,
    isBonDancePhotographyPermittedInCircle: fromBoolean(
      response.data.photography.isBonDancePhotographyPermittedInCircle
    ),
    photographyEnvironmentTypeId:
      response.data.photography.photographyEnvironmentTypeId || ID_UNCONFIRMED,
    hasNapPhoto: fromBoolean(response.data.photography.hasNapPhoto),
    hasPortableShrine: fromBoolean(response.data.photography.hasPortableShrine),
    portableShrineRouteTypeId:
      response.data.photography.portableShrineRouteTypeId || ID_UNCONFIRMED,
    riceMortarCount: response.data.photography.riceMortarCount || null,
    poundStyle: response.data.photography.poundStyle || '',
    hasMedalAward: fromBoolean(response.data.photography.hasMedalAward),
    medalAwardStyleTypeId:
      response.data.photography.medalAwardStyleTypeId || ID_UNCONFIRMED,
    medalAwardOtherDescription:
      response.data.photography.medalAwardOtherDescription || '',
    hasOgreArrival: fromBoolean(response.data.photography.hasOgreArrival),
    isCryFacePermittedForOgreArrival: fromBoolean(
      response.data.photography.isCryFacePermittedForOgreArrival
    ),
    specifiedDiplomaAwardPhotographyPositionTypeId:
      response.data.photography.hasSpecifiedDiplomaAwardPhotographyPosition ===
      null
        ? ID_UNCONFIRMED
        : response.data.photography
            .hasSpecifiedDiplomaAwardPhotographyPosition === false
        ? SPECIFIED_DIPLOMA_AWARD_PHOTOGRAPHY_POSITION_TYPE_ID_NO_POSITION
        : response.data.photography
            .specifiedDiplomaAwardPhotographyPositionTypeId || ID_UNCONFIRMED,
    specifiedDiplomaAwardPhotographyPositionOtherDescription:
      response.data.photography
        .specifiedDiplomaAwardPhotographyPositionOtherDescription || '',
    hasDiplomaAward: fromBoolean(response.data.photography.hasDiplomaAward),
    diplomaAwardTypeId:
      response.data.photography.diplomaAwardTypeId || ID_UNCONFIRMED,
    diplomaAwardCount: response.data.photography.diplomaAwardCount || null,
    diplomaAwardOtherDescription:
      response.data.photography.diplomaAwardOtherDescription || '',
    isPhotographyInsideTrackPermitted: fromBoolean(
      response.data.photography.isPhotographyInsideTrackPermitted
    ),
    hasSkippingPhotography: fromBoolean(
      response.data.photography.hasSkippingPhotography
    ),
    skippingPhotographyRemark:
      response.data.photography.skippingPhotographyRemark || '',
    openAt: response.data.photography.openAt || '',
    openingCeremonyStartAt:
      response.data.photography.openingCeremonyStartAt || '',
    hasVenueEnvironment: fromBoolean(
      response.data.photography.hasVenueEnvironment
    ),
    hasParentSeats: fromBoolean(response.data.photography.hasParentSeats),
    parentSeatTypeIds: fromNumberArray(
      response.data.photography.parentSeatTypeIds
    ),
    parentSeatOtherDescription:
      response.data.photography.parentSeatOtherDescription || '',
    lightingTypeId: response.data.photography.lightingTypeId || ID_UNCONFIRMED,
    lightingOtherDescription:
      response.data.photography.lightingOtherDescription || '',
    venueSize: response.data.photography.venueSize || '',
    rainyDayActionForPhotographerId:
      response.data.photography.rainyDayActionId ===
      RAINY_DAY_ACTION_ID_NO_CANCEL
        ? response.data.photography.hasContentChangeWhenRainy
          ? RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_HAS_CHANGE
          : RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_NO_CHANGE
        : response.data.photography.rainyDayActionId ===
          RAINY_DAY_ACTION_ID_POSTPONE
        ? response.data.photography.hasContentChangeWhenRainy === true
          ? RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_HAS_CHANGE
          : RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_CANCEL
        : RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_CANCEL,
    rainyChangePoint: response.data.photography.rainyChangePoint || '',
    rainyPhotographingPlaces:
      response.data.photography.rainyPhotographingPlaces.length > 0
        ? response.data.photography.rainyPhotographingPlaces.map(
            ({ name, address }) => ({
              name: name || '',
              address: address || '',
            })
          )
        : [{ name: '', address: '' }],
    hasMeetingPlaceChangeWhenRainy: response.data.photography
      .hasMeetingPlaceChangeWhenRainy
      ? 1
      : 0,
    cancellableDayTypeId:
      response.data.photography.cancellableDayTypeId || ID_UNCONFIRMED,
    cancelDecisionTime: response.data.photography.cancelDecisionTime || '',
    cancelNotificationTypeId:
      response.data.photography.cancelNotificationTypeId || ID_UNCONFIRMED,
    cancelNotificationOtherDescription:
      response.data.photography.cancelNotificationOtherDescription || '',
    photographingCautionRemark:
      response.data.photography.photographingCautionRemark || '',
    isPhotographerCostumeAvailable: fromBoolean(
      response.data.photography.isPhotographerCostumeAvailable,
      1
    ),
    hasFashionShow: fromBoolean(response.data.photography.hasFashionShow),
    fashionShowWalkStyle: response.data.photography.fashionShowWalkStyle || '',
    hasVideo: fromBoolean(response.data.photography.hasVideo),
    videographerTypeId:
      response.data.photography.videographerTypeId || ID_UNCONFIRMED,
    videoRemark: response.data.photography.videoRemark || '',
    hasSignboardPhoto: fromBoolean(response.data.photography.hasSignboardPhoto),
    signboardPhotoTimingTypeIds: fromNumberArray(
      response.data.photography.signboardPhotoTimingTypeIds
    ),
    signboardPhotoTargetMessageTypeId:
      response.data.photography.signboardPhotoTargetMessageTypeId ||
      ID_UNCONFIRMED,
    signboardPhotoRemark: response.data.photography.signboardPhotoRemark || '',
    photographingDayCount:
      response.data.photography.photographingDayCount || ID_UNCONFIRMED,
    hasSleepingFacePhoto: fromBoolean(
      response.data.photography.hasSleepingFacePhoto
    ),
    canSleepover: fromBoolean(response.data.photography.canSleepover),
    sleepoverPlace: response.data.photography.sleepoverPlace || '',
    photographerHotelPlaces:
      response.data.photography.photographerHotelPlaces &&
      response.data.photography.photographerHotelPlaces.length > 0
        ? response.data.photography.photographerHotelPlaces.map(
            ({ name, address }) => ({
              name: name || '',
              address: address || '',
            })
          )
        : [
            {
              name: '',
              address: '',
            },
          ],
    sleepoverMeals: getSleepoverMeals(response.data.photography.sleepoverMeals),
    sleepoverSenNote: response.data.photography.sleepoverSenNote || '',
    hasPhotographerExpense: fromBoolean(
      response.data.photography.hasPhotographerExpense
    ),
    photographerExpenseRemark:
      response.data.photography.photographerExpenseRemark || '',
    isTripodPermitted: fromBoolean(response.data.photography.isTripodPermitted),
    requireOwnTripod: fromBoolean(response.data.photography.requireOwnTripod),
    tripodSenNote: response.data.photography.tripodSenNote || '',
    isRentalStepladderAvailable: fromBoolean(
      response.data.photography.isRentalStepladderAvailable
    ),
    albumOptionSenNote: response.data.photography.albumOptionSenNote || '',
    equipmentTypeIds: fromNumberArray(
      response.data.photography.equipmentTypeIds
    ),
    equipmentSenNote: response.data.photography.equipmentSenNote || '',
    tieredPlatformSenNote:
      response.data.photography.tieredPlatformSenNote || '',
    isStrobePermitted: fromBoolean(response.data.photography.isStrobePermitted),
    strobeSenNote: response.data.photography.strobeSenNote || '',
    otherPhotographyTypeId:
      response.data.photography.otherPhotographyTypeId ||
      OTHER_EVENT_TYPE_ID_DEFAULT,
    hasDressCode: fromBoolean(response.data.photography.hasDressCode),
    diggingMethodTypeId:
      response.data.photography.diggingMethodTypeId || ID_UNCONFIRMED,
    diggingMethodOtherDescription:
      response.data.photography.diggingMethodOtherDescription || '',
    hasBirthdayChildrenPhoto: fromBoolean(
      response.data.photography.hasBirthdayChildrenPhoto
    ),
    birthdayChildrenCount:
      response.data.photography.birthdayChildrenCount || null,
    buyersSellersRemark: response.data.photography.buyersSellersRemark || '',
    shopCount: response.data.photography.shopCount || null,
    shopPhotographyVenue: response.data.photography.shopPhotographyVenue || '',
  };

  return (
    <>
      <AttachedDocument
        eventId={eventId}
        photography={response.data.photography}
        mutate={mutate}
      />
      <Prompt
        when={changed}
        message={(location) =>
          '今ページを離れると、変更が保存されません。離れますか？'
        }
      />
      <SaveForm
        onSubmit={onSubmit}
        onFormChange={handleFormChange}
        copyToClipboard={copyToClipboard}
        defaultValues={defaultValues}
        photography={response.data.photography}
        constants={response.data.constants}
        validator={validator}
      />
    </>
  );
};

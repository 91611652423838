import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { TFormInputs } from '@/components/pages/Events/Show/Photography/types';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import {
  InnerTable,
  InnerTableCell,
  InnerTableHead,
  InnerTableRow,
} from '@/components/shared/ResultTable';
import { TextInput } from '@/components/shared/Form/Inputs';
import { FiPlus } from 'react-icons/fi';
import clsx from 'clsx';

type EmergencyContactProps = {
  methods: UseFormReturn<TFormInputs>;
  validator: TValidator;
};

export const EmergencyContact: React.FC<EmergencyContactProps> = ({
  methods,
  validator,
}) => {
  const { control } = methods;
  const { append, remove } = useFieldArray({
    control,
    name: 'emergencyContacts',
  });
  const contacts = methods.watch('emergencyContacts');

  const addContact = () => {
    append({ telephoneNumber: '', name: '' });
  };

  const deleteContact = (index: number) => {
    remove(index);
  };

  return (
    <PhotographyAccordion title="当日の緊急連絡先">
      <InnerTable>
        <InnerTableHead>
          {contacts.map((_, index) => (
            <InnerTableRow key={index}>
              <InnerTableCell>
                <span>電話番号</span>
                <TextInput
                  name={`emergencyContacts.${index}.telephoneNumber`}
                  validator={validator}
                />
                <span>なまえ</span>
                <TextInput
                  name={`emergencyContacts.${index}.name`}
                  validator={validator}
                />
              </InnerTableCell>
              <InnerTableCell>
                <div className="u-align_center">
                  <button
                    type="button"
                    className={clsx(
                      'c-btn_small c-btn_delete c-btn_middle_wide',
                      { 'is-disabled': index === 0 }
                    )}
                    onClick={() => deleteContact(index)}
                  >
                    削除
                  </button>
                </div>
              </InnerTableCell>
            </InnerTableRow>
          ))}
        </InnerTableHead>
      </InnerTable>
      <button
        type="button"
        className="c-btn c-btn_large c-btn_gray_outline u-mgt_s"
        onClick={addContact}
      >
        <FiPlus />
        追加
      </button>
    </PhotographyAccordion>
  );
};

import {
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { UseFormReturn } from 'react-hook-form';
import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { Alert } from '@/components/shared/Alert';
import { ownerUrl } from '@/ts/url';
import { useParams } from 'react-router';
import { TextArea } from '@/components/shared/Form/Inputs';

export const AlbumOption: React.FC<{
  photography: TPhotography;
  methods: UseFormReturn<TFormInputs>;
  validator: TValidator;
}> = ({ photography, methods, validator }) => {
  const { eventId } = useParams<{ eventId: string }>();
  return (
    <PhotographyAccordion title="アルバム用撮影">
      あり
      <Alert type="error" title="アルバム用撮影について">
        <p>
          申請オプションで有無を判定しています。変更がある場合は申請サマリ編集から対応をお願いいたします。
        </p>
        <a
          className="c-btn c-btn_middle_wide c-btn_black"
          href={ownerUrl(`events/requests/${eventId}`)}
          target="_blank"
          rel="noreferrer"
        >
          申請サマリ編集
        </a>
      </Alert>
      <div className="t-textColor_sub">詳細</div>
      <TextArea
        name="albumOptionSenNote"
        validator={validator}
        placeholder="アルバム用撮影の詳細を入力してください"
      />
    </PhotographyAccordion>
  );
};

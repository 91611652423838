import { TValidator } from '@/components/shared/Form/types';
import {
  TConstants,
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { UseFormReturn } from 'react-hook-form';
import { RainyDayAction } from './RainyDayAction';
import { CancelDecision } from './CancelDecision';

type DealingWithUnexpectedSituationsProps = {
  methods: UseFormReturn<TFormInputs>;
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
};

export const DealingWithUnexpectedSituations: React.FC<DealingWithUnexpectedSituationsProps> =
  ({ photography, constants, validator }) => {
    return (
      <div className="c-frame">
        <div className="c-section_title">不測の事態の対応情報</div>
        <RainyDayAction
          photography={photography}
          constants={constants}
          validator={validator}
        />
        <CancelDecision
          photography={photography}
          constants={constants}
          validator={validator}
        />
      </div>
    );
  };

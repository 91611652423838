const BASIC_LIST_ITEMS = {
  eventName: {
    name: 'イベント名',
    id: 'eventName',
  },
} as const;

const EVENT_LIST_ITEMS = {
  timeSchedule: {
    name: 'タイムスケジュール',
    id: 'timeSchedule',
  },
};

const PHOTOGRAPHY_LIST_ITEMS = {
  prompting: {
    name: '声かけ',
    id: 'prompting',
  },
  signboardPhoto: {
    name: '看板前撮影',
    id: 'signboardPhoto',
  },
} as const;

export const LIST_ITEMS = {
  basic: BASIC_LIST_ITEMS,
  event: EVENT_LIST_ITEMS,
  photography: PHOTOGRAPHY_LIST_ITEMS,
} as const;

export const PARENT_LIST_ITEMS = {
  basic: {
    name: '基本情報',
    id: 'basic',
  },
  event: {
    name: 'イベントにおける情報',
    id: 'event',
  },
  photography: {
    name: '撮影における情報',
    id: 'photography',
  },
} as const;

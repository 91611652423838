import { TValidator } from '@/components/shared/Form/types';
import { TConstants, TPhotography } from '../../types';
import { PhotographyAccordion } from '../../Shared/Accordion/App';
import React from 'react';
import { EnvironmentType } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Shared/EnvironmentType';
import { NapPhoto } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Shared/NapPhoto';

export const DailyChildcare: React.FC<{
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
}> = ({ photography, validator, constants }) => {
  return (
    <>
      <PhotographyAccordion title={`${photography.eventTypeName}情報`}>
        <EnvironmentType
          validator={validator}
          photographyEnvironmentTypes={constants.photographyEnvironmentTypes}
        />

        <hr className="u-line_plane" />
        <NapPhoto validator={validator} />
      </PhotographyAccordion>
    </>
  );
};

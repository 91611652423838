import {
  NullableNumberInput,
  RadioBoxes,
  TextArea,
} from '@/components/shared/Form/Inputs';
import { PhotographyAccordion } from '../../Shared/Accordion/App';
import { TValidator } from '@/components/shared/Form/types';
import { TConstants, TPhotography, TFormInputs } from '../../types';
import {
  BON_DANCE_STYLE_TYPE_IN_CIRCLE,
  BON_DANCE_STYLE_TYPE_OTHER,
  ID_UNCONFIRMED,
} from '../../const';
import {
  InnerTable,
  InnerTableRow,
  InnerTableHead,
  InnerTableCell,
  InnerTableBody,
} from '@/components/shared/ResultTable';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';

export const SummerFestival: React.FC<{
  photography: TPhotography;
  validator: TValidator;
  constants: TConstants;
}> = ({ photography, validator, constants }) => {
  const { watch, setValue } = useFormContext<TFormInputs>();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasStalls' && type === 'change') {
        if (value.hasStalls !== 1) {
          setValue('stallsCount', null);
        }
      }
      if (name === 'hasBonDance' && type === 'change') {
        if (value.hasBonDance !== 1) {
          setValue('bonDanceStyleTypeId', ID_UNCONFIRMED);
          setValue('bonDanceStyleOtherDescription', '');
          setValue('bonDanceSongCount', null);
          setValue('bonDanceDurationMinutes', null);
          setValue('bonDanceCircleStyleTypeId', ID_UNCONFIRMED);
          setValue('isBonDancePhotographyPermittedInCircle', ID_UNCONFIRMED);
        }
      }
      if (name === 'bonDanceStyleTypeId' && type === 'change') {
        if (value.bonDanceStyleTypeId !== BON_DANCE_STYLE_TYPE_OTHER) {
          setValue('bonDanceStyleOtherDescription', '');
        }
        if (value.bonDanceStyleTypeId !== BON_DANCE_STYLE_TYPE_IN_CIRCLE) {
          setValue('bonDanceCircleStyleTypeId', ID_UNCONFIRMED);
          setValue('isBonDancePhotographyPermittedInCircle', ID_UNCONFIRMED);
        }
      }
      if (name === 'hasPortableShrine' && type === 'change') {
        if (value.hasPortableShrine !== 1) {
          setValue('portableShrineRouteTypeId', ID_UNCONFIRMED);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);
  return (
    <>
      <PhotographyAccordion title={`${photography.eventTypeName}情報`}>
        <div className="t-textColor_sub">実施場所（会場）</div>
        <TextArea
          name="summerFestivalVenue"
          validator={validator}
          rows={3}
        ></TextArea>
        <hr className="u-line_plane" />
        <div className="t-textColor_sub">ブース</div>
        <RadioBoxes
          name="hasStalls"
          choices={[
            { key: ID_UNCONFIRMED, value: '未確認' },
            { key: 1, value: 'あり' },
            { key: 0, value: 'なし' },
          ]}
          validator={validator}
        />
        {watch('hasStalls') === 1 && (
          <>
            <div className="t-textColor_sub">ブース数</div>
            <div className="l-flex_center">
              <div className={clsx(styles.numberInput)}>
                <NullableNumberInput name="stallsCount" validator={validator} />
              </div>
              箇所
            </div>
          </>
        )}
        <hr className="u-line_plane" />
        <div className="t-textColor_sub">盆踊り撮影</div>
        <RadioBoxes
          name="hasBonDance"
          choices={[
            { key: ID_UNCONFIRMED, value: '未確認' },
            { key: 1, value: 'あり' },
            { key: 0, value: 'なし' },
          ]}
          validator={validator}
        />
        {watch('hasBonDance') === 1 && (
          <>
            <div className="t-textColor_sub">踊り方</div>
            <RadioBoxes
              name="bonDanceStyleTypeId"
              choices={[
                { key: ID_UNCONFIRMED, value: '未確認' },
                ...constants.bonDanceStyleTypes,
              ]}
              validator={validator}
            />
            {watch('bonDanceStyleTypeId') === BON_DANCE_STYLE_TYPE_OTHER && (
              <>
                <div className="t-textColor_sub">その他詳細</div>
                <TextArea
                  name="bonDanceStyleOtherDescription"
                  rows={3}
                  validator={validator}
                />
              </>
            )}
            <div className="u-mgt_s"></div>
            <InnerTable>
              <InnerTableHead>
                <InnerTableRow>
                  <InnerTableCell>曲数</InnerTableCell>
                  <InnerTableCell>曲の長さ</InnerTableCell>
                  {watch('bonDanceStyleTypeId') ===
                    BON_DANCE_STYLE_TYPE_IN_CIRCLE && (
                    <>
                      <InnerTableCell>輪の形態</InnerTableCell>
                      <InnerTableCell>輪内撮影</InnerTableCell>
                    </>
                  )}
                </InnerTableRow>
              </InnerTableHead>
              <InnerTableBody>
                <InnerTableRow>
                  <InnerTableCell>
                    <span className={clsx(styles.cellLabel)}>合計</span>
                    <br />
                    <div className="l-flex_center">
                      <div className={clsx(styles.numberInput)}>
                        <NullableNumberInput
                          name="bonDanceSongCount"
                          validator={validator}
                        />
                      </div>
                      曲
                    </div>
                  </InnerTableCell>
                  <InnerTableCell>
                    <span className={clsx(styles.cellLabel)}>合計</span>
                    <br />
                    <div className="l-flex_center">
                      <div className={clsx(styles.numberInput)}>
                        <NullableNumberInput
                          name="bonDanceDurationMinutes"
                          validator={validator}
                        />
                      </div>
                      分位
                    </div>
                  </InnerTableCell>
                  {watch('bonDanceStyleTypeId') ===
                    BON_DANCE_STYLE_TYPE_IN_CIRCLE && (
                    <>
                      <InnerTableCell>
                        <RadioBoxes
                          name="bonDanceCircleStyleTypeId"
                          validator={validator}
                          choices={[
                            { key: ID_UNCONFIRMED, value: '未確認' },
                            ...constants.bonDanceCircleStyleTypes,
                          ]}
                        />
                      </InnerTableCell>
                      <InnerTableCell>
                        <RadioBoxes
                          name="isBonDancePhotographyPermittedInCircle"
                          choices={[
                            { key: ID_UNCONFIRMED, value: '未確認' },
                            { key: 1, value: '可能' },
                            { key: 0, value: '不可' },
                          ]}
                          validator={validator}
                        />
                      </InnerTableCell>
                    </>
                  )}
                </InnerTableRow>
              </InnerTableBody>
            </InnerTable>
          </>
        )}
        <hr className="u-line_plane" />
        <div className="t-textColor_sub">お神輿（山車）</div>
        <RadioBoxes
          name="hasPortableShrine"
          choices={[
            { key: ID_UNCONFIRMED, value: '未確認' },
            { key: 1, value: 'あり' },
            { key: 0, value: 'なし' },
          ]}
          validator={validator}
        />
        {watch('hasPortableShrine') === 1 && (
          <>
            <div className="t-textColor_sub">ルート</div>
            <RadioBoxes
              name="portableShrineRouteTypeId"
              choices={[
                { key: ID_UNCONFIRMED, value: '未確認' },
                ...constants.portableShrineRouteTypes,
              ]}
              validator={validator}
            />
          </>
        )}
      </PhotographyAccordion>
    </>
  );
};

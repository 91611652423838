import {
  EVENT_TYPE_ID_SLEEPOVER,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BACK_PAPER,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BACKGROUND_PAPER_STAND,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BATTERY,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_EXTRA_MEMORY_CARD,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_MONOBLOCK,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_OTHER_SPECIAL_EQUIPMENT,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_STROBE,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_TELEPHOTO_LENS,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_TIERED_PLATFORM,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_WIDE_ANGLE_LENS,
  REQUEST_OPTION_ID_ALBUM,
} from '@/components/pages/Events/Show/Photography/const';
import { TPhotography } from '@/components/pages/Events/Show/Photography/types';

export const getUnchangeableEquipmentTypes = (
  photography: TPhotography
): number[] => [
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_TIERED_PLATFORM,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_MONOBLOCK,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BACK_PAPER,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BACKGROUND_PAPER_STAND,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_OTHER_SPECIAL_EQUIPMENT,
  ...(photography.eventTypeId !== EVENT_TYPE_ID_SLEEPOVER
    ? [PHOTOGRAPHY_EQUIPMENT_TYPE_ID_STROBE]
    : []),
  ...(!photography.requestOptions.includes(REQUEST_OPTION_ID_ALBUM)
    ? [
        PHOTOGRAPHY_EQUIPMENT_TYPE_ID_WIDE_ANGLE_LENS,
        PHOTOGRAPHY_EQUIPMENT_TYPE_ID_TELEPHOTO_LENS,
        PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BATTERY,
        PHOTOGRAPHY_EQUIPMENT_TYPE_ID_EXTRA_MEMORY_CARD,
      ]
    : []),
];

import { TValidator } from '@/components/shared/Form/types';
import {
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import {
  InnerTable,
  InnerTableBody,
  InnerTableCell,
  InnerTableHead,
  InnerTableRow,
} from '@/components/shared/ResultTable';
import { TextInput } from '@/components/shared/Form/Inputs';
import { FiPlus } from 'react-icons/fi';
import { Alert } from '@/components/shared/Alert';
import clsx from 'clsx';
import { useParams } from 'react-router';
import { ownerUrl } from '@/ts/url';

type PhotographingPlaceProps = {
  methods: UseFormReturn<TFormInputs>;
  photography: TPhotography;
  validator: TValidator;
};

export const PhotographingPlace: React.FC<PhotographingPlaceProps> = ({
  methods,
  photography,
  validator,
}) => {
  const { eventId } = useParams<{ eventId: string }>();
  const { control } = methods;
  const { append, remove } = useFieldArray({
    control,
    name: 'photographingPlaces',
  });
  const places = methods.watch('photographingPlaces');

  const addPlace = () => {
    append({ name: '', address: '' });
  };

  const deletePlace = (index: number) => {
    remove(index);
  };

  return (
    <div className="c-frame">
      <div className="c-section_title">撮影場所</div>
      <hr className="u-line_plane" />
      <PhotographyAccordion title="場所詳細">
        <div>{photography.photographingPlace ?? '園'}</div>
        {photography.photographingPlaceMemo && (
          <>
            <div className="t-bgBox_gray">
              {photography.photographingPlaceMemo}
            </div>
            <div className="t-textColor_sub">
              ※申請サマリの登録情報を表示しています。
            </div>
          </>
        )}
        <Alert type="error" title="撮影場所について">
          <p>
            撮影場所の名称が変更になった場合は変更申請より対応してください。
            <br />
            住所については当画面で必要な数だけ項目を追加して登録してください。
          </p>
          <a
            className="c-btn c-btn_middle_wide c-btn_black"
            href={ownerUrl(`events/requests/${eventId}`)}
            target="_blank"
            rel="noreferrer"
          >
            申請サマリ編集
          </a>
        </Alert>
        <InnerTable>
          <InnerTableHead>
            <InnerTableRow>
              <InnerTableCell colSpan={2}>
                <div className="u-align_center">場所詳細</div>
              </InnerTableCell>
            </InnerTableRow>
          </InnerTableHead>
          <InnerTableBody>
            {places.map((_, index) => (
              <InnerTableRow key={index}>
                <InnerTableCell>
                  <span>施設名</span>
                  <TextInput
                    name={`photographingPlaces.${index}.name`}
                    validator={validator}
                  />
                  <span>住所</span>
                  <TextInput
                    name={`photographingPlaces.${index}.address`}
                    validator={validator}
                  />
                </InnerTableCell>
                <InnerTableCell>
                  <div className="u-align_center">
                    <button
                      type="button"
                      className={clsx(
                        'c-btn_small c-btn_delete c-btn_middle_wide',
                        { 'is-disabled': index === 0 }
                      )}
                      onClick={() => deletePlace(index)}
                    >
                      削除
                    </button>
                  </div>
                </InnerTableCell>
              </InnerTableRow>
            ))}
          </InnerTableBody>
        </InnerTable>
        <button
          type="button"
          className="c-btn c-btn_large c-btn_gray_outline u-mgt_s"
          onClick={addPlace}
        >
          <FiPlus />
          追加
        </button>
      </PhotographyAccordion>
    </div>
  );
};

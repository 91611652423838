import { TPhotography } from '../types';
import { TFormInputs } from '../types';
import { ID_UNCONFIRMED } from '../const';
import { LIST_ITEMS, PARENT_LIST_ITEMS } from '../listItem';
import { shouldRenderSignboardPhoto } from '../Shared/shouldRenderPhotographyContent';

export type TCheckListChild = {
  name: string;
  scrollTarget: string;
  visible: (photography: TPhotography, inputValues: TFormInputs) => boolean;
} & (
  | {
      checked: (inputValues: TFormInputs) => boolean;
    }
  | { star: true }
);

export type TCheckListParent = {
  name: string;
  scrollTarget: string;
  children: TCheckListChild[];
};

const basicListItem: TCheckListParent = {
  name: PARENT_LIST_ITEMS.basic.name,
  scrollTarget: PARENT_LIST_ITEMS.basic.id,
  children: [
    {
      name: LIST_ITEMS.basic.eventName.name,
      scrollTarget: LIST_ITEMS.basic.eventName.id,
      visible: () => true,
      checked: () => true,
    },
  ],
};

const eventListItem: TCheckListParent = {
  name: PARENT_LIST_ITEMS.event.name,
  scrollTarget: PARENT_LIST_ITEMS.event.id,
  children: [
    {
      name: LIST_ITEMS.event.timeSchedule.name,
      scrollTarget: LIST_ITEMS.event.timeSchedule.id,
      visible: () => true,
      star: true,
    },
  ],
};

const photographyListItem: TCheckListParent = {
  name: PARENT_LIST_ITEMS.photography.name,
  scrollTarget: PARENT_LIST_ITEMS.photography.id,
  children: [
    {
      name: LIST_ITEMS.photography.prompting.name,
      scrollTarget: LIST_ITEMS.photography.prompting.id,
      visible: () => true,
      checked: (inputValues) => inputValues.canPrompt !== ID_UNCONFIRMED,
    },
    {
      name: LIST_ITEMS.photography.signboardPhoto.name,
      scrollTarget: LIST_ITEMS.photography.signboardPhoto.id,
      visible: (photography) => shouldRenderSignboardPhoto(photography),
      checked: (inputValues) =>
        inputValues.hasSignboardPhoto !== ID_UNCONFIRMED,
    },
  ],
};

export const checkListItems: TCheckListParent[] = [
  basicListItem,
  eventListItem,
  photographyListItem,
];

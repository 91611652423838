import React, { useEffect } from 'react';
import { TValidator } from '@/components/shared/Form/types';
import {
  TConstants,
  TFormInputs,
} from '@/components/pages/Events/Show/Photography/types';
import { useFormContext } from 'react-hook-form';
import {
  FARMING_EXPERIENCE_DIGGING_METHOD_OTHER_TYPE,
  ID_UNCONFIRMED,
} from '@/components/pages/Events/Show/Photography/const';
import { EnvironmentType } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Shared/EnvironmentType';
import { NapPhoto } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Shared/NapPhoto';
import { RadioBoxes, TextArea } from '@/components/shared/Form/Inputs';

export const FarmingExperience: React.FC<{
  validator: TValidator;
  constants: TConstants;
}> = ({ validator, constants }) => {
  const { watch, setValue } = useFormContext<TFormInputs>();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'diggingMethodTypeId' && type === 'change') {
        if (
          value.diggingMethodTypeId !==
          FARMING_EXPERIENCE_DIGGING_METHOD_OTHER_TYPE
        ) {
          setValue('diggingMethodOtherDescription', '');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);
  return (
    <>
      <EnvironmentType
        validator={validator}
        photographyEnvironmentTypes={constants.photographyEnvironmentTypes}
      />

      <hr className="u-line_plane" />
      <NapPhoto validator={validator} />

      <hr className="u-line_plane" />
      <div className="t-textColor_sub">堀り方</div>
      <RadioBoxes
        name="diggingMethodTypeId"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          ...constants.farmingExperienceDiggingMethodTypes,
        ]}
      />

      {watch('diggingMethodTypeId') ===
        FARMING_EXPERIENCE_DIGGING_METHOD_OTHER_TYPE && (
        <>
          <div className="t-textColor_sub">その他詳細</div>
          <TextArea
            name="diggingMethodOtherDescription"
            placeholder="堀り方の詳細を入力してください"
            rows={3}
            validator={validator}
          />
        </>
      )}
    </>
  );
};

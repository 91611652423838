import { TValidator } from '@/components/shared/Form/types';
import { TextArea } from '@/components/shared/Form/Inputs';
import { Alert } from '@/components/shared/Alert';

type PhotographyCautionProps = {
  validator: TValidator;
};

export const PhotographyCaution: React.FC<PhotographyCautionProps> = ({
  validator,
}) => {
  return (
    <div className="c-frame">
      <div className="c-section_title">撮影時の注意点</div>
      <hr className="u-line_plane" />
      <TextArea
        name="photographingCautionRemark"
        validator={validator}
        rows={15}
        placeholder="撮影時の注意点を入力してください"
      />
      <Alert type="error" title="撮影時の注意点について">
        <p>
          団体サマリの「撮影時の注意点」に記載されている情報を初期値として表示しています。
          <br />
          記載内容をそのままカメラマンに共有するため、不要な事項は必ず削除をしてください。
        </p>
        <p style={{ fontWeight: 'bold' }}>
          ※ 変更を加えても団体サマリには上書きされません
        </p>
      </Alert>
    </div>
  );
};

import { TConstants } from '@/components/pages/Events/Show/Photography/types';
import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { Checkboxes, TextArea } from '@/components/shared/Form/Inputs';

type BelongingsProps = {
  constants: TConstants;
  validator: TValidator;
};

export const Belongings: React.FC<BelongingsProps> = ({
  constants,
  validator,
}) => {
  return (
    <PhotographyAccordion title="持ち物">
      <Checkboxes
        name="belongingsTypeIds"
        validator={validator}
        choices={constants.photographyBelongingsTypes}
      />
      <div className="t-textColor_sub">詳細</div>
      <TextArea
        name="belongingsSenNote"
        validator={validator}
        placeholder="持ち物の詳細を入力してください"
      />
    </PhotographyAccordion>
  );
};

import { TValidator } from '@/components/shared/Form/types';
import {
  TConstants,
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { useFormContext } from 'react-hook-form';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import {
  RadioBoxes,
  TextArea,
  TextInput,
} from '@/components/shared/Form/Inputs';

import { Alert } from '@/components/shared/Alert';
import {
  CANCEL_NOTIFICATION_TYPE_ID_OTHER,
  CANCELLABLE_DAY_TYPE_ID_THAT_DAY,
  ID_UNCONFIRMED,
} from '@/components/pages/Events/Show/Photography/const';
import { useEffect } from 'react';

export const CancelDecision: React.FC<{
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
}> = ({ photography, constants, validator }) => {
  const methods = useFormContext<TFormInputs>();
  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (name === 'cancellableDayTypeId' && type === 'change') {
        if (value.cancellableDayTypeId !== CANCELLABLE_DAY_TYPE_ID_THAT_DAY) {
          methods.setValue('cancelDecisionTime', '');
        }
      }
      if (name === 'cancelNotificationTypeId' && type === 'change') {
        if (
          value.cancelNotificationTypeId !== CANCEL_NOTIFICATION_TYPE_ID_OTHER
        ) {
          methods.setValue('cancelNotificationOtherDescription', '');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [methods, photography]);
  return (
    <PhotographyAccordion title="中止／撮影内容変更 連絡">
      <div className="t-textColor_sub">判断タイミング</div>
      <RadioBoxes
        name="cancellableDayTypeId"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          ...constants.cancellableDayTypes,
        ]}
      />
      {methods.watch('cancellableDayTypeId') ===
        CANCELLABLE_DAY_TYPE_ID_THAT_DAY && (
        <>
          <div className="t-textColor_sub">時間</div>
          <TextInput name="cancelDecisionTime" validator={validator} />
        </>
      )}
      <div className="t-textColor_sub">連絡方法</div>
      <RadioBoxes
        name="cancelNotificationTypeId"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          ...constants.photographyCancelNotificationTypes,
        ]}
      />
      {methods.watch('cancelNotificationTypeId') ===
        CANCEL_NOTIFICATION_TYPE_ID_OTHER && (
        <>
          <div className="t-textColor_sub">その他詳細</div>
          <TextArea
            name="cancelNotificationOtherDescription"
            validator={validator}
          />
        </>
      )}
      <Alert type="warning" title="当日の途中中止について">
        <p>
          「当日万が一、途中で中止となった場合はその場で担当営業もしくは弊社にご連絡ください。」の注釈文がコピーの際に追加されます。
        </p>
      </Alert>
    </PhotographyAccordion>
  );
};

import {
  InnerTable,
  InnerTableHead,
  InnerTableRow,
  InnerTableCell,
  InnerTableBody,
} from '@/components/shared/ResultTable';
import { PhotographyAccordion } from '../../Shared/Accordion/App';
import { TValidator } from '@/components/shared/Form/types';
import {
  NullableNumberInput,
  TextInput,
} from '@/components/shared/Form/Inputs';
import { TConstants, TFormInputs, TPhotography } from '../../types';
import { ID_UNCONFIRMED } from '../../const';
import { useFormContext } from 'react-hook-form';
import React, { useEffect } from 'react';
import { EnvironmentType } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Shared/EnvironmentType';

export const MochiPound: React.FC<{
  photography: TPhotography;
  validator: TValidator;
  constants: TConstants;
}> = ({ photography, validator, constants }) => {
  const { watch, setValue } = useFormContext<TFormInputs>();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'photographyEnvironmentTypeId' && type === 'change') {
        if (value.photographyEnvironmentTypeId === ID_UNCONFIRMED) {
          setValue('riceMortarCount', null);
          setValue('poundStyle', '');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);
  return (
    <>
      <PhotographyAccordion title={`${photography.eventTypeName}情報`}>
        <EnvironmentType
          validator={validator}
          photographyEnvironmentTypes={constants.photographyEnvironmentTypes}
        />
        {watch('photographyEnvironmentTypeId') !== ID_UNCONFIRMED && (
          <>
            <div className="u-mgt_s" />
            <InnerTable>
              <InnerTableHead>
                <InnerTableRow>
                  <InnerTableCell>臼の数</InnerTableCell>
                  <InnerTableCell>つき方</InnerTableCell>
                </InnerTableRow>
              </InnerTableHead>
              <InnerTableBody>
                <InnerTableRow>
                  <InnerTableCell>
                    <div className="l-flex_center nowrap">
                      <div className="u-mgr_s">
                        <NullableNumberInput
                          name="riceMortarCount"
                          validator={validator}
                        />
                      </div>
                      基
                    </div>
                  </InnerTableCell>
                  <InnerTableCell>
                    <span className="t-textColor_sub u-fz_s">
                      ※一名ずつ、複数名ずつ（⚪︎名ずつ）など記載してください。※また学年によって異なる場合はそれぞれ記載をお願いいたします。
                    </span>
                    <TextInput name="poundStyle" validator={validator} />
                  </InnerTableCell>
                </InnerTableRow>
              </InnerTableBody>
            </InnerTable>
          </>
        )}
      </PhotographyAccordion>
    </>
  );
};

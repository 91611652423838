import React from 'react';
import clsx from 'clsx';
import { Link, Route, useLocation, useParams } from 'react-router-dom';
import { Summary } from '@/components/pages/Events/Show/Summary';
import { kanriUrl, ownerUrl } from '@/ts/url';
import { renderError, useJsonApi } from '@/ts/useApi';
import Loading from '@/components/shared/Loading/App';
import { ShowPageLayout } from '@/components/pages/Events/Show/ShowPageLayout';
import { TMenuTitlesResponse } from '@/components/pages/Events/Show/types';
import { TabItems } from '@/components/shared/TabList/TabItems';
import styles from './style.module.scss';
import { BreadCrumb } from '@/components/shared/BreadCrumb';
import { Overview } from '@/components/pages/Events/Show/Overview';
import { BiLinkExternal } from 'react-icons/bi';
import { TabRow } from '@/components/shared/TabList/TabRow';
import { Photography } from './Photography';
import { Helmet } from 'react-helmet-async';

const App: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const { data: response, error } = useJsonApi<TMenuTitlesResponse>(
    '/api/events/' + eventId + '/title_sidemenu_contents'
  );
  const pathName = useLocation().pathname;
  if (error) return renderError(error);
  if (!response || !pathName) return <Loading />;
  return (
    <>
      <Route path="/events/:eventId">
        <Helmet>
          <title>{response.societyName} - イベントサマリ | 千管理画面</title>
        </Helmet>
      </Route>
      <Route path="/events/:eventId/overview">
        <Helmet>
          <title>{response.societyName} - 概要 | 千管理画面</title>
        </Helmet>
      </Route>
      <Route path="/events/:eventId/photographies">
        <Helmet>
          <title>{response.societyName} - 撮影情報 | 千管理画面</title>
        </Helmet>
      </Route>
      <ShowPageLayout response={response}>
        <BreadCrumb
          pagesInfo={[
            { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
            {
              id: 2,
              title: 'イベント一覧',
              path: '/events',
            },
            { id: 3, title: 'イベント情報', path: undefined },
          ]}
        />
        <div className="navigation">
          <h1 className="l-flex_center u-mgb_m c-page_title">
            <i className="c-icon c-icon__xxsmall c-icon_event u-mgr_xs"></i>
            {eventId} / {response.eventName}
          </h1>
          <p>
            <span className={styles.textBorder}>{response.eventTypeName}</span>
            <i className="c-icon c-icon__xxsmall c-icon_groups u-mgr_xs"></i>
            {` ${response.societyId} / ${response.societyName}`}
          </p>
        </div>
        <TabList eventId={eventId} pathName={pathName} response={response} />
        <Contents />
      </ShowPageLayout>
    </>
  );
};

const Contents: React.FC = () => {
  return (
    <div className="App p-eventsSummary c-summary">
      <div className={clsx('l-content', styles.contentOverwrite)}>
        <div className="l-center_wrap">
          <Route path="/events/:eventId/overview">
            <Overview />
          </Route>
          <Route path="/events/:eventId/photographies">
            <Photography />
          </Route>
          <Route exact path="/events/:eventId">
            <Summary />
          </Route>
        </div>
      </div>
    </div>
  );
};

const TabList: React.FC<{
  pathName: string;
  eventId: string;
  response: TMenuTitlesResponse;
}> = ({ pathName, eventId, response }) => {
  return (
    <TabRow>
      {response.hasEventRequest && (
        <>
          <TabItems selected={pathName.endsWith(`/events/${eventId}/overview`)}>
            <Link
              className={styles.noneUnderLine}
              to={`/events/${eventId}/overview`}
            >
              概要
            </Link>
          </TabItems>
          {process.env.REACT_APP_FEATURE_PHOTOGRAPHY === 'true' && (
            <TabItems
              selected={pathName.endsWith(`/events/${eventId}/photographies`)}
            >
              <Link
                className={styles.noneUnderLine}
                to={`/events/${eventId}/photographies`}
              >
                撮影情報
              </Link>
            </TabItems>
          )}
        </>
      )}
      <TabItems selected={pathName.endsWith(`/events/${eventId}`)}>
        <Link className={styles.noneUnderLine} to={`/events/${eventId}`}>
          イベントサマリ
        </Link>
      </TabItems>
      {response.hasEventRequest && (
        <TabItems selected={false}>
          <div className={styles.tabLink}>
            <a
              className="c-textlink"
              href={ownerUrl(`events/requests/${eventId}`)}
              target="_blank"
              rel="noreferrer"
            >
              申請サマリ
              <BiLinkExternal />
            </a>
          </div>
        </TabItems>
      )}
      <TabItems selected={false}>
        <div className={styles.tabLink}>
          <Link to={`/societies/${response.societyId}`} target="_blank">
            団体情報
            <BiLinkExternal />
          </Link>
        </div>
      </TabItems>
      {response.hasDetailsMailLink && (
        <TabItems selected={false}>
          <div className={styles.tabLink}>
            <a
              href={kanriUrl({
                action_owner_SHOUSAIinput: 'true',
                eventno: eventId,
              })}
              target="_blank"
              rel="noreferrer"
            >
              詳細メール
              <BiLinkExternal />
            </a>
          </div>
        </TabItems>
      )}
    </TabRow>
  );
};

export default App;
